import PropTypes from "prop-types";
import React from "react";
import ListCalendar from "../ListCalendar";
import _ from "underscore";
import Wrapper from "./Wrapper";
import { Macros } from "../../models/Macros";
import { toSearchCriteria } from "../../lib/FilterUtils";
import { FORM_TYPES } from "./PrefsCoreAPI";
import API from "../../lib/TimeEditAPI";

const findFilter = (reservationData) => {
    if (reservationData.objects) {
        return reservationData.objects.filter((object) => object.fields && !object.id);
    }
    return null;
};

type TSelectTypeCallBackArg = typeof _.noop | ((res: any) => void);
const getType = (extId, callback: TSelectTypeCallBackArg = _.noop) => {
    API.getTypesByExtid(_.asArray(extId), false, (result) => {
        callback(result[0]);
    });
};

class PrefsComponentCalendar extends React.Component {
    static contextTypes = {
        fireEvent: PropTypes.func,
        focusFirstCalendar: PropTypes.func,
    };

    getRefreshEvents = () => [];

    onTypeSelected = (type, callback) => {
        this.context.fireEvent("prefsComponent", Macros.Event.SELECT_EXTERNAL_TYPE, type, callback);
    };

    onObjectSelected = (objects, type) => {
        this.context.fireEvent("prefsComponent", Macros.Event.SELECT_EXTERNAL_OBJECT, {
            objects,
            type,
        });
    };

    // searchCriteria is either a criteria object, or an array of objects for different types
    onSearchCriteriaSelected = (
        searchCriteria,
        changeType = true,
        openSearchSettings = false,
        callback = _.noop
    ) => {
        const doSelectCriteria = (criteria) => {
            // If setting criteria for a single type, we also select it in the GUI
            const finish = () => {
                this.context.fireEvent(
                    "prefsComponent",
                    Macros.Event.SET_EXTERNAL_OBJECT_SEARCH_CRITERIA,
                    {
                        searchCriteria: criteria,
                        openSearchSettings,
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    (_result) => {
                        callback(criteria);
                    }
                );
            };
            if (changeType && criteria.type) {
                this.onTypeSelected(criteria.type, finish);
            } else if (changeType && criteria.length === 1) {
                this.onTypeSelected(criteria[0].type, finish);
            } else {
                finish();
            }
        };

        if (searchCriteria.type) {
            getType(searchCriteria.type, (type) => {
                const newCriteria = _.extend({}, searchCriteria, { type });
                doSelectCriteria(newCriteria);
            });
        } else {
            doSelectCriteria(searchCriteria);
        }
    };

    onPopulateSelection = (reservationData, useSpotlight = true) => {
        const postType = () => {
            this.context.fireEvent(
                "prefsComponent",
                Macros.Event.SET_EXTERNAL_SELECTION,
                reservationData,
                () => {
                    // Filters are typed fields in reservationData, so onSearchCriteriaSelected or equivalent function needs to be able to set multiple filters
                    const filters = findFilter(reservationData);
                    if (filters && filters.length > 0) {
                        this.onSearchCriteriaSelected(
                            toSearchCriteria(filters),
                            true,
                            false,
                            _.noop
                        );
                    }
                    const focusDate = reservationData.begin || reservationData.focusDate;
                    if (focusDate) {
                        this.context.fireEvent(
                            `prefsInCore`,
                            Macros.Event.SET_EXTERNAL_DATE,
                            focusDate,
                            null,
                            useSpotlight
                        );
                    }
                }
            );
        };
        this.context.focusFirstCalendar(false);
        if (reservationData.formType) {
            const type = FORM_TYPES[reservationData.formType] || 1;
            this.context.fireEvent(
                `prefsInCore`,
                Macros.Event.SET_EXTERNAL_TEMPLATE_KIND,
                type,
                () => {
                    postType();
                }
            );
        } else {
            postType();
        }
    };

    onRequestOperation = (operationType, data = {}, callback = _.noop) => {
        this.context.fireEvent("prefsComponent", Macros.Event.REQUEST_OPERATION, {
            operationType,
            data,
            callback,
        });
    };

    render() {
        let style = { width: this.props.width, height: "100%" };
        if (this.props.style) {
            style = this.props.style;
        }
        const classes = _.extend(
            {
                reservationList: true,
            },
            this.props.classes
        );
        return (
            <ListCalendar
                {...this.props}
                className={`${this.props.className} prefsComponent`}
                type={"prefsComponent"}
                refreshEvents={this.getRefreshEvents()}
                selectType={this.onTypeSelected}
                selectObject={this.onObjectSelected}
                setSearchCriteria={this.onSearchCriteriaSelected}
                populateSelection={this.onPopulateSelection}
                requestOperation={this.onRequestOperation}
                privateSelected={false}
                listRef={(node) => {
                    this._wrapper = node;
                }}
                fireEvent={this.context.fireEvent}
            >
                <Wrapper classes={classes} style={style} />
            </ListCalendar>
        );
    }
}

export default PrefsComponentCalendar;
