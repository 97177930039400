import { Header } from "./Header";
import { MillenniumDateTime } from "@timeedit/millennium-time";
import Language from "../lib/Language";
import _ from "underscore";
import { Entry } from "./Entry";

export class PeriodHeader extends Header {
    id: number;
    names: string[];
    values: any[];
    name: any;

    constructor(visibleValues, firstVisibleValue, subheader?, typeName?) {
        super(visibleValues || 1, firstVisibleValue, subheader, typeName || "PeriodHeader");
        this.id = 0;
        this.names = [];
        this.values = [];
        this.size = 18;
    }

    static KIND = {
        TIME: 1,
        DATE: 2,
        WEEK: 3,
        WEEKDAY: 4,
    };

    getKind() {
        throw new Error("All period headers must implement getKind");
    }

    validateValues() {
        if (this.names.length !== this.getValues().length) {
            throw new Error("Invalid period header");
        }
        let i;
        const isInvalid = this.values.some((value, index) => {
            i = index;
            return !Array.isArray(value) || value.length === 0;
        });
        if (isInvalid) {
            throw new Error(
                `Invalid period header in period header ${this.id}, no value for "${this.names[i]}"`
            );
        }
    }

    indexOf(entry: Entry, onlyVisible: boolean) {
        if (
            !entry.hasOwnProperty("periods") ||
            entry.periods === undefined ||
            !entry.periods.hasOwnProperty(this.getId())
        ) {
            if (this.isSimplePeriod()) {
                return this.getSimplePeriodIndex(entry, onlyVisible);
            }
            throw new Error("Could not find matching period for entry.");
        }

        let index = entry.periods[this.getId()];
        if (Array.isArray(index)) {
            index = index[0];
        }
        if (onlyVisible) {
            return index - this.firstVisibleValue;
        }

        return index;
    }
    getSimplePeriodIndex(entry: Entry, onlyVisible: any) {
        throw new Error("Method not implemented.");
    }

    lastIndexOf(entry: Entry, onlyVisible: boolean) {
        if (
            !entry.hasOwnProperty("periods") ||
            entry.periods === undefined ||
            !entry.periods.hasOwnProperty(this.getId())
        ) {
            if (this.isSimplePeriod()) {
                return this.getSimplePeriodIndex(entry, onlyVisible) + 1;
            }
            throw new Error("Could not find matching period for entry.");
        }

        let index = entry.periods[this.getId()];
        if (Array.isArray(index)) {
            index = index[index.length - 1];
        }
        index = index + 1;
        if (onlyVisible) {
            return index - this.firstVisibleValue;
        }
        return index;
    }

    valueAt(index: number, onlyVisible = true) {
        if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
            console.error(`Index out of bounds in PeriodHeader.valueAt(${index})`);
            return null;
        }

        const values = onlyVisible ? this.getVisibleValues() : this.getValues();
        return values[index];
    }

    // eslint-disable-next-line no-unused-vars
    getLabel(value, size) {
        // eslint-disable-line no-unused-vars
        let label = "N/A";
        this.getValues().forEach((item, index) => {
            if (item === value) {
                label = this.names[index];
            }
        }, this);
        return label;
    }

    getInfo(value) {
        if (value === this.values[this.firstVisibleValue]) {
            return this.name;
        }

        return null;
    }

    getValues() {
        return this.values;
    }

    getClusterDepth(index) {
        return this.getValues()[index].length;
    }

    // eslint-disable-next-line no-unused-vars
    getMaxClusterDepth(index) {
        // eslint-disable-line no-unused-vars
        return this.getValues().reduce((max, values) => Math.max(values.length, max), 1);
    }

    /**
     * A simple period is a period where all cells only contains a single value and
     * no values overlap eachother.
     */
    // eslint-disable-next-line no-unused-vars
    isSimplePeriod(index?) {
        // eslint-disable-line no-unused-vars
        const hasOnlySingleValues = this.getValues().every((values) => values.length === 1);
        if (!hasOnlySingleValues) {
            return false;
        }

        const values = _.flatten(this.getValues());
        const getMatchingValues = (value) =>
            values.filter((otherValue) => value.equals(otherValue));
        return values.every((value) => getMatchingValues(value).length === 1);
    }

    // eslint-disable-next-line no-unused-vars
    getSettings(providers?) {
        // eslint-disable-line no-unused-vars
        const settings = super.getSettings();
        const self = this;

        const firstVisibleValue = settings.find("firstVisibleValue");
        firstVisibleValue.label = Language.get("nc_cal_reservation_list_column_start_period");
        firstVisibleValue.type = "array";
        firstVisibleValue.limit = 1;
        firstVisibleValue.get = () =>
            this.getValues().map((period, index) => ({
                value: index,
                label: self.getLabel(period, Header.Label.XL, index, false),
                selected: index === self.firstVisibleValue,
            }));

        const visibleValues = settings.find("visibleValues");
        visibleValues.label = Language.get("cal_res_side_view_visible_periods");

        return settings;
    }

    periodsToJSON() {
        throw new Error("All period headers must implement periodsToJSON");
    }

    toJSON() {
        const json = super.toJSON();
        return _.extend(json, {
            id: this.id,
            names: this.names,
            kind: "date",
            size: this.size,
        });
    }

    static getDateTimeFromPeriods(headers, indexes) {
        let weekPeriod;
        let weekdayPeriod;
        const dateTimeObjects = _.filter(
            headers.map((header, i) => {
                if (header.typeName === "WeekPeriodHeader") {
                    weekPeriod = header;
                } else if (header.typeName === "WeekdayPeriodHeader") {
                    weekdayPeriod = header;
                } else if (header.typeName === "TimePeriodHeader") {
                    return undefined;
                }
                return header.getValues()[indexes[i]];
            }),
            (idx) => idx !== undefined
        ); // Filtering works around the case where a view is changing and the header basically contains nothing, then the array will contain a single undefined

        if (!weekPeriod && weekdayPeriod) {
            dateTimeObjects.push(weekdayPeriod.getWeeks().map((wk) => wk.value));
        } else if (weekPeriod && !weekdayPeriod) {
            dateTimeObjects.push(weekPeriod.weekdays);
        }

        if (dateTimeObjects.length === 0 || _.flatten(dateTimeObjects).length === 0) {
            return [];
        }

        try {
            return MillenniumDateTime.createFromList(dateTimeObjects);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            return [];
        }
    }
}
