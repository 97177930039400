import {
    MillenniumDate,
    MillenniumWeekday,
    MillenniumWeek,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import { Header } from "./Header";
import { getWeekLimits } from "./utils";
import Locale from "../lib/Language";
import _ from "underscore";

export const WEEKDAY_HEADER_DAYS_IN_WEEK = 7;

export class WeekdayHeader extends Header {
    weeks: any[];
    futureWeeksOnly: boolean;

    constructor(visibleValues, firstVisibleValue?, subheader?) {
        super(
            visibleValues || WEEKDAY_HEADER_DAYS_IN_WEEK,
            firstVisibleValue,
            subheader,
            "WeekdayHeader"
        );
        this.weeks = [MillenniumWeek.today(Locale.firstDayOfWeek, Locale.daysInFirstWeek)];
        this.size = 18;
        this.futureWeeksOnly = false;
    }

    indexOf(entry, onlyVisible) {
        const date = entry.startTimes[0].getMillenniumDate();
        return this.indexOfDayOfWeek(date, onlyVisible);
    }

    lastIndexOf(entry, onlyVisible) {
        const date = entry.endTimes[0].getMillenniumDate();
        return this.indexOfDayOfWeek(date, onlyVisible) + 1;
    }

    indexOfDayOfWeek(date, onlyVisible) {
        const dayOfWeek = new MillenniumWeek(
            date,
            Locale.firstDayOfWeek,
            Locale.daysInFirstWeek
        ).getDayOfWeek(date);
        let i;
        const values = onlyVisible ? this.getVisibleValues() : this.getValues();
        const numValues = values.length;
        for (i = 0; i < numValues; i++) {
            if (values[i].getAbsoluteWeekdayNumber() === dayOfWeek) {
                return i;
            }
        }

        throw new Error("Could not find entry time among week days.");
    }

    getIndexOfDate(date, onlyVisible = false) {
        const week = new MillenniumWeek(date, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
        // The date needs to be within one of the weeks the header displays
        if (!_.any(this.weeks, (wk) => wk.isSameWeekAs(week))) {
            return -1;
        }
        const weekday = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        // Not found if weekday not displayed
        const values = onlyVisible ? this.getVisibleValues() : this.getValues();
        return values.map((wkd) => wkd.getAbsoluteWeekdayNumber()).indexOf(weekday);
    }

    isCurrent(millenniumWeekday, currentDateTime) {
        if (!currentDateTime) {
            return false;
        }
        const today = currentDateTime.getMillenniumDate();
        const currentWeek = new MillenniumWeek(
            today,
            Locale.firstDayOfWeek,
            Locale.daysInFirstWeek
        );
        const dayOfWeek = currentWeek.getDayOfWeek(today);
        const isRightWeekDay = millenniumWeekday.getAbsoluteWeekdayNumber() === dayOfWeek;
        if (this.weeks) {
            const hasWeek = this.weeks.some((week) => currentWeek.week(true) === week.week(true));
            if (!hasWeek) {
                return false;
            }
        }
        return isRightWeekDay;
    }

    valueAt(index, onlyVisible = true) {
        if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
            throw new Error(`Index out of bounds in WeekdayHeader.valueAt(${index})`);
        }

        const values = onlyVisible ? this.getVisibleValues() : this.getValues();
        return values[index];
    }

    getLabel(value, size) {
        switch (size) {
            case Header.Label.XS:
                return SimpleDateFormat.format(value, "E");
            case Header.Label.S:
                return SimpleDateFormat.format(value, "EE");
            case Header.Label.M:
                return SimpleDateFormat.format(value, "EEE");
            default:
                return SimpleDateFormat.format(value, "EEEE");
        }
    }

    getInfo(value, size, customWeekNames, providers) {
        if (providers.week || !this.weeks || value !== this.valueAt(0, true)) {
            return null;
        }

        const formatter = (period) => {
            const weekToString = (week) => {
                if (customWeekNames.length > 0) {
                    const customWeek = getCustomWeek(week, customWeekNames);
                    if (customWeek !== null) {
                        return customWeek.getShortestName();
                    }
                }
                return SimpleDateFormat.format(
                    week.getStartOfWeek(),
                    Locale.getDateFormat("date_f_yyyy_ww")
                );
            };
            if (Array.isArray(period)) {
                return `${weekToString(period[0])} - ${weekToString(period[period.length - 1])}`;
            }
            return weekToString(period);
        };
        return MillenniumWeek.toString(this.weeks, formatter);
    }

    getValues() {
        return MillenniumWeekday.getLocalizedWeekdayList(Locale.firstDayOfWeek);
    }

    getVisibleValues() {
        const values = [].concat(this.getValues());
        values.splice(0, this.firstVisibleValue);
        values.splice(this.visibleValues);
        return values;
    }

    getSettings(providers, customWeekNames?) {
        const settings = super.getSettings(this);
        const self = this;

        const labels = Locale.getWeekdayLabels();
        const values = this.getValues().map((val) => ({ value: val, label: labels[val.getDay()] }));

        const firstVisibleValue = settings.find("firstVisibleValue");
        firstVisibleValue.type = "array";
        firstVisibleValue.limit = 1;
        firstVisibleValue.get = function () {
            return values.map((item, index) =>
                _.extend({}, item, {
                    selected: index === self.firstVisibleValue,
                })
            );
        };
        firstVisibleValue.set = function (weekday) {
            const newFirstVisibleValue = values.findIndex(
                (day) => day.value.getDay() === weekday.getDay()
            );
            let newVisibleValues = self.visibleValues;
            if (newFirstVisibleValue + newVisibleValues > WEEKDAY_HEADER_DAYS_IN_WEEK) {
                newVisibleValues = WEEKDAY_HEADER_DAYS_IN_WEEK - newFirstVisibleValue;
            }

            return self
                .immutableSet({ visibleValues: newVisibleValues })
                .immutableSet({ firstVisibleValue: newFirstVisibleValue });
        };

        const visibleValues = settings.find("visibleValues");
        visibleValues.type = "array";
        visibleValues.limit = 1;
        visibleValues.get = function () {
            return _.range(1, WEEKDAY_HEADER_DAYS_IN_WEEK + 1).map((item) => ({
                value: item,
                label: item,
                selected: item === self.visibleValues,
            }));
        };
        visibleValues.set = function (newVisibleValues) {
            let newFirstVisibleValue = self.firstVisibleValue;
            if (newFirstVisibleValue + newVisibleValues > WEEKDAY_HEADER_DAYS_IN_WEEK) {
                newFirstVisibleValue = WEEKDAY_HEADER_DAYS_IN_WEEK - newVisibleValues;
            }
            return self.immutableSet({
                visibleValues: newVisibleValues,
                firstVisibleValue: newFirstVisibleValue,
            });
        };

        if (providers.week === true) {
            return settings;
        }

        settings.items.push({
            id: "weeks",
            label: Locale.get("cal_list_weeks"),
            type: "array",
            limit: 0,
            get: self.getWeeks.bind(self, customWeekNames),
            set(val) {
                return self.immutableSet({ weeks: val });
            },
        });

        return settings;
    }

    getWeeks(customWeekNames = []) {
        const values: any[] = [];

        const startDay = this.limits.getStartDate().getDayNumber();
        const weekLimits = getWeekLimits(this.limits);
        for (
            let day = startDay;
            day < startDay + this.limits.dayCount;
            day = day + WEEKDAY_HEADER_DAYS_IN_WEEK
        ) {
            const week = new MillenniumWeek(
                new MillenniumDate(day),
                Locale.firstDayOfWeek,
                Locale.daysInFirstWeek
            );
            const label =
                getCustomWeekName(week, customWeekNames) ||
                SimpleDateFormat.format(
                    week.getStartOfWeek(),
                    Locale.getDateFormat("date_f_yyyy_ww_l")
                );
            if (week.week() >= weekLimits.start.week() && week.week() <= weekLimits.end.week()) {
                values.push({
                    value: week,
                    label,
                });
            }
        }

        return values.map((item) =>
            _.extend({}, item, {
                selected: this.weeks.some((week) => week.week() === item.value.week()),
            })
        );
    }

    toJSON() {
        const json = super.toJSON();
        const currentWeek = MillenniumWeek.today(Locale.firstDayOfWeek, Locale.daysInFirstWeek);
        let weeks = this.weeks;
        if (weeks.length === 1 && weeks[0].week() === currentWeek.week()) {
            weeks = [];
        }

        return _.extend(json, {
            dayProvider: true,
            kind: "weekday",
            size: this.size,
            weeks: weeks.map((week) => `20${week.week()}`),
            futureWeeksOnly: this.futureWeeksOnly,
        });
    }
}

const getCustomWeek = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name;
    }
    return null;
};

const getCustomWeekName = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name.getLongestName();
    }
    return null;
};
