import { EntryKind } from "../lib/EntryConstants";
import { Entry, Entry as EntryModel } from "../models/Entry";
import { MillenniumTime, MillenniumDateTime } from "@timeedit/millennium-time";
import { TimeConstants } from "./TimeConstants";

import _ from "underscore";

const BORDER_STRETCH = 1;

const getIdentifier = (entry) => {
    const etr = entry.model ? entry.model : entry;
    return `${(etr.reservationids || []).join(":")}.${etr.startTimes
        .map((time) => time.getMts())
        .join(":")}.${etr.endTimes.map((time) => time.getMts()).join(":")}`;
};

// Room for improvement.
// Now checks overlap using style (i.e. pixel positions), allowing one pixel of overlap which can occur because of rounding of pixel positions
// The right thing to do would probaby be to pass in entries and have a overlap function on each header responsible for checking
// the appropriate properties on the entries for overlap
const overlaps = (style1, style2, isVertical) => {
    if (style1 === undefined || style2 === undefined) {
        return false;
    }
    if (isVertical) {
        if (style1.top === style2.top) {
            return true;
        }
        if (style1.top < style2.top && style1.top + style1.height > style2.top) {
            const diff = Math.abs(style1.top + style1.height - style2.top);
            if (diff > 1) {
                return true;
            }
        }
        if (style2.top < style1.top && style2.top + style2.height > style1.top) {
            const diff = Math.abs(style2.top + style2.height - style1.top);
            if (diff > 1) {
                return true;
            }
        }
    } else {
        if (style1.left === style2.left) {
            return true;
        }
        if (style1.left < style2.left && style1.left + style1.width > style2.left) {
            const diff = Math.abs(style1.left + style1.width - style2.left);
            if (diff > 1) {
                return true;
            }
        }
        if (style2.left < style1.left && style2.left + style2.width > style1.left) {
            const diff = Math.abs(style2.left + style2.width - style1.left);
            if (diff > 1) {
                return true;
            }
        }
    }
    return false;
};

const getColSpan = (entry, columnMap, startIndex, currentSpan, isVertical) => {
    if (!columnMap[startIndex]) {
        return currentSpan;
    }
    const leftSubId = getIdentifier(entry.subEntry);
    if (
        _.any(columnMap[startIndex], (etr) => {
            const rightSubId = getIdentifier(etr.subEntry);
            return (
                leftSubId !== rightSubId &&
                overlaps(
                    entry.styles.find((style) => style.identifier === leftSubId),
                    etr.styles.find((style) => style.identifier === rightSubId),
                    isVertical
                )
            );
        })
    ) {
        return currentSpan;
    }
    return getColSpan(entry, columnMap, startIndex + 1, currentSpan + 1, isVertical);
};

const createIndexMap = (entries, isVertical) => {
    const columnMap: any[] = [];
    entries.forEach((entry) => {
        let added = false;
        columnMap.forEach((column) => {
            // If the column contains no entry which overlaps this one, add the entry to the column
            if (added) {
                return;
            }
            if (
                !_.some(
                    column,
                    (etr) =>
                        getIdentifier(entry) !== getIdentifier(etr) &&
                        _.any(entry.styles, (style) =>
                            _.any(etr.styles, (stl) => overlaps(style, stl, isVertical))
                        )
                )
            ) {
                added = true;
                column.push(entry);
            }
        });
        if (!added) {
            columnMap.push([entry]);
        }
    });
    // Return a map where the property for an entry has column index and column span as the value
    const indexMap: any = {};
    columnMap.forEach((column, index) => {
        column.forEach((entry) => {
            const colSpan = getColSpan(entry, columnMap, index + 1, 1, isVertical);
            indexMap[getIdentifier(entry)] = { column: index, colSpan };
        });
    });
    indexMap.numColumns = columnMap.length;
    return indexMap;
};

const adjustSideBySideEntries = (entryGroups, isVertical) => {
    Object.keys(entryGroups).forEach((key) => {
        const group = entryGroups[key];
        // eslint-disable-next-line no-magic-numbers
        if (group.length < 2) {
            return;
        }
        const indexes = createIndexMap(group, isVertical);
        // eslint-disable-next-line no-magic-numbers
        if (indexes.numColumns < 2) {
            return;
        }
        group.forEach((entry) => {
            const identifier = getIdentifier(entry);
            const subIdentifier = getIdentifier(entry.subEntry);
            const { column, colSpan } = indexes[identifier];
            if (column !== undefined) {
                // eslint-disable-next-line no-param-reassign
                entry.model = entry.model.clone();
                // eslint-disable-next-line no-param-reassign
                entry.model.isSideBySide = true;
                entry.styles.forEach((style) => {
                    if (style.identifier === subIdentifier) {
                        if (isVertical) {
                            const colWidth = style.width / indexes.numColumns;
                            const newWidth = colWidth * colSpan;
                            // eslint-disable-next-line no-param-reassign
                            style.width = newWidth;
                            // eslint-disable-next-line no-param-reassign
                            style.left = style.left + column * colWidth;
                        } else {
                            const colHeight = style.height / indexes.numColumns;
                            const newHeight = colHeight * colSpan;
                            // eslint-disable-next-line no-param-reassign
                            style.height = newHeight;
                            // eslint-disable-next-line no-param-reassign
                            style.top = style.top + column * colHeight;
                        }
                    }
                });
            }
        });
    });
};

const timeSlotToEntries = (
    slot,
    duplicateForObjects = true,
    inHeaderObjects: any[] | null = null,
    props = { periodValues: [], visibleDates: [] },
    getStyleDescriptor = _.noop
) => {
    const datesForWeekday = (dates, weekday) =>
        _.filter(dates, (date) => date.getDay() === weekday);

    const toEntries = (slt, dates) => {
        const endTimes = slt.end_times ? slt.end_times : [slt.end_time];
        if (endTimes === undefined || endTimes[0] === undefined) {
            //console.log(slt);
            // If landing here, end times from the rule should be used. But this isn't maintainable for old systems.
            return [];
        }
        let headerObjects = inHeaderObjects ? inHeaderObjects.map((ho) => ho.objects) : [];
        if (headerObjects.length > 0) {
            headerObjects = _.combineArrays(headerObjects);
        }
        let result = _.flatten(
            dates.map((date) =>
                _.flatten(
                    endTimes.map((endTime) => {
                        const entry = new EntryModel(
                            [new MillenniumDateTime(date, new MillenniumTime(slt.start_time))],
                            [
                                new MillenniumDateTime(
                                    date,
                                    new MillenniumTime(
                                        endTime === TimeConstants.SECONDS_PER_DAY
                                            ? endTime - 1
                                            : endTime
                                    )
                                ),
                            ]
                        );
                        entry.periods = props.periodValues;
                        return entry;
                    })
                )
            )
        ).filter((entry) => entry !== undefined);
        if (duplicateForObjects && headerObjects.length > 0 && result.length > 0) {
            result = _.flatten(
                headerObjects.map((ho) =>
                    result.map((entry: Entry) => {
                        const headerEntry = entry.clone();
                        headerEntry.objects = ho;
                        return headerEntry;
                    })
                )
            );
        }
        return _.filter(
            result,
            (entry) => entry.startTimes[0].getMts() < entry.endTimes[0].getMts()
        );
    };

    return _.flatten(
        slot.week_days.map((weekday) => {
            const entries = toEntries(
                slot,
                slot.date ? [slot.date] : datesForWeekday(props.visibleDates, weekday)
            );
            return entries
                .map((entry) => {
                    const result = getStyleDescriptor(entry);
                    if (!result) {
                        return null;
                    }
                    result.entry = entry;
                    return result;
                })
                .filter((result) => result !== null);
        })
    );
};

// allEntries includes the entry currently being processed
const isObscured = (entry, allEntries) => {
    // eslint-disable-next-line no-magic-numbers
    if (entry.model.overlapCount < 2 || !entry.model.overlaps) {
        return false;
    }
    const styleOverlaps = (style1, style2) => {
        const max1x = style1.top + style1.height;
        const max1y = style1.left + style1.width;
        const max2x = style2.top + style2.height;
        const max2y = style2.left + style2.width;

        return !(
            max1x <= style2.top ||
            style1.top >= max2x ||
            style1.left >= max2y ||
            max1y <= style2.left
        );
    };

    const stylesOverlap = (styles1, styles2) =>
        styles1.some((style) => styles2.some((st) => styleOverlaps(style, st)));

    const overlappers = allEntries
        .filter((etr) => stylesOverlap(entry.styles, etr.styles))
        .sort((e1, e2) => e1.model.getLength() - e2.model.getLength());
    return entry !== overlappers[0];
};

// allEntries includes the entry currently being processed
const isObscuredOld = (entry, allEntries) => {
    if (entry.model.overlapCount < 2 || !entry.model.overlaps) {
        return false;
    }

    const overlappers = allEntries
        .filter((etr) => entry.model.overlaps(etr.model))
        .sort((e1, e2) => e1.model.getLength() - e2.model.getLength());

    return entry !== overlappers[0];
};

const EntryUtils = {
    BORDER_STRETCH,
    isObscuredOld,
    isObscured,
    adjustSideBySideEntries,
    createIndexMap,
    getColSpan,
    overlaps,
    getIdentifier,

    timeSlotsToEntries: (
        slotData,
        duplicateForObjects = true,
        headerObjects = null,
        props: any | null = null,
        getStyleDescriptor = _.noop
    ) => {
        const result = _.flatten(
            slotData.map((slot) =>
                timeSlotToEntries(
                    slot,
                    duplicateForObjects,
                    headerObjects,
                    props,
                    getStyleDescriptor
                )
            )
        );
        return result;
    },

    getEntryKey: (entry, index) => {
        if (entry.kind === EntryKind.NONE) {
            return `ghost${entry.startTimes[0].getMts()}${entry.endTimes[0].getMts()}${entry.reservationids.join(
                ""
            )}`;
        }
        const periods = Object.getOwnPropertyNames(entry.periods).map(
            (period) => `${period}.${entry.periods[period]}`
        );
        let reservationIds = entry.reservationids;
        if (reservationIds.length === 0 || _.every(entry.reservationids, (id) => id === 0)) {
            reservationIds = ["nri", index];
        }
        return `${entry.startTimes[0].getMts()}-${entry.endTimes[0].getMts()}i${reservationIds.join(
            "i"
        )}o${entry.objects.join("o")}p${periods.join("p")}c${entry.clusterId || ""}idx${index}`;
    },

    getEntryStyleFromArray: (styleArray, defaultWidth, defaultHeight) => {
        const defaultStyle = {
            left: 0,
            top: 0,
            width: defaultWidth,
            height: defaultHeight,
            cutoffLeft: false,
            cutoffRight: false,
            cutoffTop: false,
            cutoffBottom: false,
        };
        const isDefined = (v) => v !== null && v !== undefined;

        return styleArray.reduce((previous, current) => {
            // Cut-off left
            if (isDefined(current.left) && current.left < 0) {
                // eslint-disable-next-line no-param-reassign
                current.cutoffLeft = true;
                // eslint-disable-next-line no-param-reassign
                current.width += current.left;
                // eslint-disable-next-line no-param-reassign
                current.left = 0;
            }

            // Cut-off top
            if (isDefined(current.top) && current.top < 0) {
                // eslint-disable-next-line no-param-reassign
                current.cutoffTop = true;
                // eslint-disable-next-line no-param-reassign
                current.height += current.top;
                // eslint-disable-next-line no-param-reassign
                current.top = 0;
            }

            // Cut-off right
            if (
                isDefined(current.left) &&
                isDefined(current.width) &&
                current.left + current.width - BORDER_STRETCH > previous.width
            ) {
                // eslint-disable-next-line no-param-reassign
                current.cutoffRight = true;
                // eslint-disable-next-line no-param-reassign
                current.width = previous.width - current.left - BORDER_STRETCH;
            }

            // Cut-off bottom
            if (
                isDefined(current.top) &&
                isDefined(current.height) &&
                current.top + current.height - BORDER_STRETCH > previous.height
            ) {
                // eslint-disable-next-line no-param-reassign
                current.cutoffBottom = true;
                // eslint-disable-next-line no-param-reassign
                current.height = previous.height - current.top - BORDER_STRETCH;
            }

            return {
                left: isDefined(current.left) ? previous.left + current.left : previous.left,
                top: isDefined(current.top) ? previous.top + current.top : previous.top,
                width: isDefined(current.width) ? current.width : previous.width,
                height: isDefined(current.height) ? current.height : previous.height,
                cutoffLeft: current.cutoffLeft,
                cutoffRight: current.cutoffRight,
                cutoffTop: current.cutoffTop,
                cutoffBottom: current.cutoffBottom,
            };
        }, defaultStyle);
    },
};

export default EntryUtils;
