import { Model } from "./Model";
import { Entry } from "./Entry";
import { Limits } from "./Limits";
import { Settings } from "./Settings";

import Language from "../lib/Language";
import _ from "underscore";

/**
 * Base header object.
 */

export class Header extends Model {
    visibleValues: number;
    subheader: Header | null;
    isActive: boolean;
    isScrollable: boolean;
    showInfo: boolean;
    hideGrid: boolean;
    hide: boolean;
    limits: any | null;
    majorStepLimit: number;
    size: number;
    _firstVisibleValue: any;
    typeName:
        | "TimeHeader"
        | "PeriodHeader"
        | "TimePeriodHeader"
        | "DateHeader"
        | "Header"
        | "WeekPeriodHeader"
        | "WeekdayPeriodHeader"
        | "DatePeriodHeader";
    isSideBySide: boolean;
    sideBySideValues: any[];

    static Label: { XS: number; S: number; M: number; L: number; XL: number } = {
        XS: 0,
        S: 1,
        M: 2,
        L: 3,
        XL: 4,
    };

    // firstVisibleValue?: number;

    constructor(visibleValues, firstVisibleValue, subheader?, typeName?) {
        super(typeName ? typeName : "Header");
        this.visibleValues = visibleValues < 1 ? 1 : visibleValues;
        this.subheader = subheader || null;
        this.isActive = false;
        this.isScrollable = true;
        this.showInfo = false;
        this.hideGrid = false;
        this.hide = false;
        this.limits = null;
        this.majorStepLimit = 0;
        this.size = 0;
        this._firstVisibleValue = firstVisibleValue || 0;
        this.typeName = typeName || "Header";
        this.isSideBySide = false;
        this.sideBySideValues = [];
    }

    isDateOrTime(): boolean {
        return this.typeName === "DateHeader" || this.typeName === "TimeHeader";
    }

    getVisibleValues(): any {
        return this.getValues().filter((el, index) => {
            return (
                index >= this.firstVisibleValue! &&
                index < this.firstVisibleValue + this.visibleValues
            );
        }, this);
    }

    getValues(): any[] {
        throw new Error("Header must implement getValues method.");
    }

    lastIndexOf(entry: Entry, onlyVisible: boolean) {
        throw new Error("Header must implement lastIndexOf method.");
    }

    getSections(): any[] {
        return [];
    }

    getHeaders(onlyVisible = false): Header[] {
        const headers: Header[] = [];
        let currentHeader: Header | null = this;
        // eslint-disable-next-line no-unmodified-loop-condition
        while (currentHeader !== null && (!onlyVisible || !currentHeader.hide)) {
            headers.push(currentHeader);
            currentHeader = currentHeader.subheader;
        }
        return headers;
    }

    getActiveHeader(): Header | undefined {
        return _.find(this.getHeaders(), (header: Header) => header.isActive);
    }

    getNumberOfHeaderItems(): number {
        let currentHeader: Header | null = this;
        let count = 0;
        while (currentHeader !== null) {
            if (currentHeader.hide) {
                break;
            }
            // eslint-disable-next-line no-magic-numbers
            count = count + (currentHeader.showInfo ? 2 : 1);
            currentHeader = currentHeader.subheader;
        }
        return count;
    }

    setLimits(limits: any): any {
        if (!(limits instanceof Limits)) {
            // eslint-disable-next-line no-param-reassign
            limits = new Limits(limits);
        }

        const diff: {
            limits: any;
            subheader?: Header;
        } = {
            limits,
        };

        if (this.subheader) {
            diff.subheader = this.subheader.setLimits(limits);
        }

        return this.immutableSet(diff);
    }

    addSubheader(subheader: any): any {
        return this.immutableSet({
            subheader: subheader.immutableSet({ subheader: this.subheader }),
        });
    }

    setActiveHeader(activeHeader: Header): any {
        const diff: {
            subheader?: Header;
            isActive: boolean;
        } = {
            isActive: this === activeHeader,
        };

        if (this.subheader) {
            diff.subheader = this.subheader.setActiveHeader(activeHeader);
        }

        return this.immutableSet(diff);
    }

    hasTime(): boolean {
        if (this.typeName === "TimeHeader") {
            return true;
        }
        return this.subheader ? this.subheader.hasTime() : false;
    }

    hasTimePeriod(): boolean {
        if (this.typeName === "PeriodHeader" || this.typeName === "TimePeriodHeader") {
            return true;
        }
        return this.subheader ? this.subheader.hasTimePeriod() : false;
    }

    increaseFirstVisibleValue() {
        if (this.majorStepLimit && this.visibleValues >= this.majorStepLimit) {
            return this.increaseMajorStep();
        }

        if (this.firstVisibleValue + this.visibleValues + 1 > this.length(false)) {
            return this;
        }
        return this.immutableSet({
            firstVisibleValue: this.firstVisibleValue! + 1,
        });
    }

    increaseMajorStep() {
        throw new Error(
            "Header must implement increaseMajorStep method if a major step limit is set."
        );
    }

    length(onlyVisible?: boolean) {
        if (onlyVisible) {
            return this.getVisibleValues().length;
        }

        return this.getValues().length;
    }

    decreaseFirstVisibleValue() {
        if (this.majorStepLimit && this.visibleValues >= this.majorStepLimit) {
            return this.decreaseMajorStep();
        }

        if (this.firstVisibleValue! - 1 < 0) {
            return this;
        }
        return this.immutableSet({
            firstVisibleValue: this.firstVisibleValue! - 1,
        });
    }

    decreaseMajorStep() {
        throw new Error(
            "Header must implement increaseMajorStep method if a major step limit is set."
        );
    }

    getPosition(entry: Entry): number {
        let position = this.indexOf(entry, true)! / this.visibleValues;

        if (this.subheader) {
            position += this.subheader.getPosition(entry) / this.visibleValues;
        }

        return position;
    }

    indexOf(entry?: any, arg1?: boolean): number | undefined {
        throw new Error("Method not implemented.");
    }

    swapHeader(): any {
        return this.immutableSet((newHeader) => {
            const newTopHeader = newHeader.subheader.subheader;
            // eslint-disable-next-line no-param-reassign
            newHeader.subheader.subheader = newTopHeader.subheader;
            newTopHeader.subheader = newHeader.subheader;
            // eslint-disable-next-line no-param-reassign
            newHeader.subheader = newTopHeader;
        });
    }

    setSubheader(newSubheader: Header | null) {
        let isActive = this.isActive;
        if (!newSubheader && this.subheader && this.subheader.isActive) {
            isActive = true;
        }

        if (!newSubheader) {
            // eslint-disable-next-line no-param-reassign
            newSubheader = this.subheader ? this.subheader.subheader : null;
        }
        return this.immutableSet({
            subheader: newSubheader,
            isActive,
        });
    }

    setShowGrid(showGrid: boolean) {
        let subheader = this.subheader;
        if (subheader && !showGrid) {
            subheader = subheader.setShowGrid(false);
        }

        return this.immutableSet({
            hideGrid: !showGrid,
            subheader,
        });
    }

    setVisibleValues(visibleValues: any) {
        if (visibleValues < 1) {
            // eslint-disable-next-line no-param-reassign
            visibleValues = 1;
        }

        const max = this.length();
        if (visibleValues > max) {
            // eslint-disable-next-line no-param-reassign
            visibleValues = max;
        }

        const delta: {
            visibleValues: any;
            firstVisibleValue?: any;
        } = { visibleValues };
        if (this.firstVisibleValue + visibleValues > this.length()) {
            delta.firstVisibleValue = this.length() - visibleValues;
        }

        return this.immutableSet(delta);
    }

    setSize(size: any) {
        return this.immutableSet({ size });
    }

    resetSize() {
        let subheader = this.subheader;
        if (subheader) {
            subheader = subheader.resetSize();
        }

        return this.immutableSet({
            size: 0,
            subheader,
        });
    }

    isCurrent(date: any, currentDateTime: any): boolean {
        return false;
    }

    hasInfo(): boolean {
        return true;
    }

    getInfo(...args): string | null {
        return null;
    }

    getCellsPerInfoCell(): number {
        return 1;
    }

    isNewSection(index: any): boolean {
        return false;
    }

    toggleSideBySideForValue(value: any) {
        const val = JSON.stringify(value);
        if (this.sideBySideValues.indexOf(val) !== -1) {
            return this.immutableSet({
                sideBySideValues: this.sideBySideValues.filter((idx) => idx !== val),
            });
        }
        return this.immutableSet({ sideBySideValues: this.sideBySideValues.concat(val) });
    }

    isSideBySideAtIndex(index: any) {
        return (
            this.isSideBySide ||
            this.sideBySideValues.indexOf(JSON.stringify(this.getVisibleValues()[index])) !== -1
        );
    }

    getSettings(providers?: any) {
        // eslint-disable-next-line global-require
        const self = this;
        const list = new Settings([
            {
                id: "firstVisibleValue",
                label: Language.get("nc_header_settings_first_visible_value"),
                type: "integer",
                get() {
                    return self.firstVisibleValue;
                },
                set(val) {
                    return self.immutableSet({ firstVisibleValue: val });
                },
            },
            {
                id: "visibleValues",
                label: Language.get("nc_header_settings_visible_values"),
                type: "integer",
                get() {
                    return self.visibleValues;
                },
                set(val) {
                    return self.setVisibleValues(val);
                },
            },
            {
                id: "showGrid",
                label: Language.get("cal_res_side_view_grid"),
                type: "boolean",
                get() {
                    return !self.hideGrid;
                },
                set(val) {
                    return self.setShowGrid(val);
                },
            },
            {
                id: "showHeader",
                label: Language.get("cal_res_side_view_header"),
                type: "boolean",
                get() {
                    return !self.hide;
                },
                set(val) {
                    return self.immutableSet({ hide: !val });
                },
            },
        ]);

        if (this.hasInfo() || this.showInfo === true) {
            list.items.push({
                id: "showInfo",
                label: Language.get("cal_res_side_view_info_header"),
                type: "boolean",
                get() {
                    return self.showInfo;
                },
                set(val) {
                    return self.immutableSet({ showInfo: val });
                },
            });
        }

        if (!this.subheader) {
            list.items.push({
                id: "isSideBySide",
                label: Language.get("nc_side_by_side_title"),
                details: Language.get("nc_side_by_side_details"),
                type: "boolean",
                get() {
                    return self.isSideBySide;
                },
                set(val) {
                    return self.immutableSet({ isSideBySide: val });
                },
            });
        }

        return list;
    }

    toJSON() {
        return {
            firstValue: this.firstVisibleValue,
            hideGrid: this.hideGrid,
            hideHeader: this.hide,
            scrollable: this.isScrollable,
            showInfo: this.showInfo,
            visibleValues: this.visibleValues,
            timeProvider: false,
            dayProvider: false,
            size: 0,
            isSideBySide: this.subheader ? false : this.isSideBySide,
            sideBySideValues: this.sideBySideValues,
        };
    }

    get firstVisibleValue() {
        return this._firstVisibleValue;
    }
    set firstVisibleValue(val) {
        if (!_.isNumber(val)) {
            throw new Error(Language.get("nc_validation_error_not_an_integer", val));
        }
        if (val % 1 !== 0) {
            throw new Error(Language.get("nc_validation_error_not_an_integer", val));
        }
        this._firstVisibleValue = val;
        if (val < 0) {
            this._firstVisibleValue = 0;
        }
        if (this.isDateOrTime() && val > this.length() - this.visibleValues) {
            this._firstVisibleValue = this.length() - this.visibleValues;
        }
    }
}

// Make firstVisibleValue getter/setter enumerable.
Object.defineProperty(Header.prototype, "firstVisibleValue", { enumerable: true });
