import PropTypes from "prop-types";
import React from "react";
import Entry from "../components/Entry";
import { TEntryCssClasses, TEntryModelWithStyles } from "../types/models/TEntry";
import Utils from "../lib/EntryUtils";

import { EntryKind, TClusterKindValue } from "../lib/EntryConstants";
import { DateHeader } from "../models/DateHeader";
import { DatePeriodHeader } from "../models/DatePeriodHeader";
import { PeriodHeader } from "../models/PeriodHeader";
import { WeekdayHeader } from "../models/WeekdayHeader";
import { WeekHeader } from "../models/WeekHeader";
import { WeekdayPeriodHeader } from "../models/WeekdayPeriodHeader";
import { WeekPeriodHeader } from "../models/WeekPeriodHeader";
import { Entry as EntryModel } from "../models/Entry";
import Grid from "../lib/Grid";
import { TimeEdit } from "../lib/TimeEdit";
import { Header } from "../models/Header";
import { AvailabilityEntry } from "../models/AvailabilityEntry";
import _ from "underscore";
import { TimeConstants as TC } from "../lib/TimeConstants";
import ReservationConstants from "../lib/ReservationConstants";
import Mousetrap from "@timeedit/mousetrap";
import ReactDOM from "react-dom";
import { TTimeSlot } from "../types/api/TMcFluffy";
import { MillenniumDate } from "@timeedit/millennium-time";
import { TColorType } from "../types/api/TColorTypes";
import { Kind } from "../models/TemplateKind";

const LINE_COLORS = ["#bbb", "#ccc", "#ddd", "#ddd"];
const SPECIAL_LINE_COLOR = "#666";
const BORDER_STRETCH = Utils.BORDER_STRETCH;
const MIN_WIDTH_FOR_TEXT = 30;
const MIN_HEIGHT_FOR_TEXT = 15;
const TOOLTIP_SHOW_DELAY = 2000;
const TOOLTIP_SHOW_DELAY_IN_CONFLICT_MODE = 200;
const TOOLTIP_HIDE_DELAY = 0;

const MULTI_COLOR_ID = 13001;
const BG_MULTIPLIER = 1.11;

const NARROW_CUTOFF = 56;
const SIZE_CUTOFF = 40;
const SMALL_MOD = 4;
const LARGE_MOD = 10;
const HEIGHT_MODIFIER = 5;

const THREE = 3;

const notAllOccluded = (imagePart) => imagePart.flat().some((val) => val === 0);

class EntryMap {
    width = 0;
    height = 0;
    backingArray: number[] = [];
    constructor(width, height) {
        this.width = width;
        this.height = height;
        for (let i = 0; i < width * height; i++) {
            this.backingArray[i] = 0;
        }
    }
    setRowPart(row, start, length, value = 1) {
        const startPos = row * this.width + start;
        for (let i = startPos; i < startPos + length; i++) {
            this.backingArray[i] = value;
        }
    }
    setArea(x, y, width, height, value = 1) {
        const x2 = Math.floor(x);
        const y2 = Math.floor(y);
        const width2 = Math.floor(width);
        const height2 = Math.floor(height);
        for (let i = y2; i < y2 + height2; i++) {
            this.setRowPart(i, x2, width2, value);
        }
    }
    clearArea(x, y, width, height) {
        this.setArea(x, y, width, height, 0);
    }
    getRowPart(row, start, length) {
        const result: number[] = [];
        const startPos = row * this.width + start;
        for (let i = startPos; i < startPos + length; i++) {
            result.push(this.backingArray[i]);
        }
        return result;
    }
    getArea(x, y, width, height) {
        const x2 = Math.floor(x);
        const y2 = Math.floor(y);
        const width2 = Math.floor(width);
        const height2 = Math.floor(height);
        const result: number[][] = [];
        for (let i = y2; i < y2 + height2; i++) {
            result.push(this.getRowPart(i, x2, width2));
        }
        return result;
    }
    getWholeMap() {
        return this.getArea(0, 0, this.width, this.height);
    }
    printMap() {
        // eslint-disable-next-line no-console
        console.table(this.getWholeMap());
    }
    addMap(otherMap) {
        this.backingArray.forEach((value, index) => {
            this.backingArray[index] = value || otherMap.backingArray[index];
        });
    }
}

interface EntryLayerProps {
    isActive: boolean;
    size: { width: number; height: number };
    maxClusterDepth: number;
    yHeader: Header;
    xHeader: Header;
    skipBackgroundText: boolean;
    timeSlots: TTimeSlot[];
    headerObjects: Header[];
    availableSlots: TTimeSlot[];
    background?: boolean;
    isAbsoluteAvailability: boolean;
    unlockedReservations: number[];
    selectionGroup: EntryModel[];
    getClusterValues: (periodCombination: any) => unknown[];
    isSizeMode: boolean;
    availability: AvailabilityEntry[];
    entries: EntryModel[];
    fallbackDate: MillenniumDate;
    colorTypes: TColorType[];
    visibleDates: any[];
    dragElements: EntryModel[];
    onMouseDown: React.MouseEventHandler;
    onMouseUp: React.MouseEventHandler;
    onEntryClick(entry: EntryModel, addToSelection: boolean, shiftKey: boolean);
    onLockedEntryClick: () => void;
    infoEntryReservationIds: number[];
    paddingActive: boolean;
    isConflictMode: boolean;
    renderTooltip: (el: React.JSX.Element, id: string) => void;
    unrenderTooltip: () => void;
    isOverlapView: boolean;
    onObjectInfo: (object: any, createCopy?: boolean, viewOnly?: boolean, title?: string) => void;
    selectionContainsGroups: (selectionGroup?: EntryModel[]) => boolean;
    isGroupMode: boolean;
    addToGroup: (entry: EntryModel) => void;
    addReservationToTime: (entry: EntryModel) => void;
    createGroup: (idsArg: number[]) => void;
    isLegalGroup: boolean;
    selectedReservationIds: number[];
    activeLayer: number;
    isWeekCluster: boolean;
    readOnly: boolean;
    templateKind: Kind;
    openStaticReservationListAdd: (reservationIds: number[], add: boolean) => void;
    onDynamicReservationIdsChanged: (newDynamicIds: number[]) => void;
    infoEntryReservationids: number[];
    showOverlappingEntriesInList: (entry: EntryModel) => void;
    showOverlappingEntries: (entry: EntryModel) => void;
    onEntryDelete: (reservationId: number, shouldSendEmail: boolean, cb?: () => void) => void;
    moveToWaitingList: (reservationIds: number[], singleReservation: boolean) => void;
    emailReservation: (reservationIds: number[], includeSelection: boolean) => void;
    enableEditMode: (entry: EntryModel, opts: {}, cb: () => void) => void;
    onEntryDragStart: (
        currentEntry: EntryModel,
        type: string,
        event: MouseEvent,
        isHighRes?: boolean
    ) => void;
    onEditReservationExceptions(entry: EntryModel);
    onEntryInfoOpen: (
        entry: EntryModel | EntryModel[] | number[],
        showPanel: boolean,
        editMode: boolean
    ) => void;
    onEntryCopy(entry: EntryModel, event: Event);
    clusterKind: TClusterKindValue;
    editGroup: (entry: EntryModel, groups: number[], date: MillenniumDate) => void;
    spotlightDate: MillenniumDate;
    createGroupFromEntry: (entry: EntryModel) => void;
    deleteGroup: (groupIds: number[] | number) => void;
    fluffySize: number;
    splitReservation: (entry: EntryModel, cb: () => void) => void;
}

class EntryLayer extends React.Component<EntryLayerProps> {
    static contextTypes = {
        user: PropTypes.object,
        colorDefs: PropTypes.array,
        useNewReservationGroups: PropTypes.bool,
        env: PropTypes.object,
    };

    state = {
        tooltipVisible: false,
        isTooltipLocked: false,
        useOcclusion: true,
        activeTooltip: undefined,
    };
    private _prevEntries?: TEntryModelWithStyles[];
    private _prevForegroundEntries?: TEntryModelWithStyles[];
    private _prevBackgroundEntries?: TEntryModelWithStyles[];
    private _prevBorderEntries?: TEntryModelWithStyles[];
    private _parentCalendarElemRef: any;
    private _toolTipTimeout?: NodeJS.Timeout | null;

    visibilityListener() {
        if (document.visibilityState === "visible") {
            this.updateBackground();
            this.updateBackgroundText();
        }
    }

    componentDidMount() {
        if (this.props.isActive) {
            this.registerKeyboardShortcuts();
        }
        this.updateBackground();
        this.updateBackgroundText();
        document.addEventListener("visibilitychange", this.visibilityListener.bind(this));

        // Get reference to closest parent calendar container once for passing to child components.
        this._parentCalendarElemRef = (ReactDOM.findDOMNode(this) as HTMLElement).closest(
            ".calendar, .entryOverlay"
        );
    }

    componentWillUnmount() {
        clearTimeout(this._toolTipTimeout);
        document.removeEventListener("visibilitychange", this.visibilityListener);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isActive && this.props.isActive) {
            this.registerKeyboardShortcuts();
        }
        if (
            this.props.size.width !== prevProps.size.width ||
            this.props.size.height !== prevProps.size.height ||
            this.props.maxClusterDepth !== prevProps.maxClusterDepth ||
            this.hasHeaderBackgroundChanged(this.props.xHeader, prevProps.xHeader) ||
            this.hasHeaderBackgroundChanged(this.props.yHeader, prevProps.yHeader)
        ) {
            this.updateBackground();
        }
        if (this.props.skipBackgroundText !== prevProps.skipBackgroundText) {
            this.updateBackgroundText();
        }
        const availabilitySlots = this.getSubSlots();
        this.updateAvailability(availabilitySlots);
        this.updateSoftAvailability(availabilitySlots);
        this.updateTimeSlots(this.props.timeSlots, this.props.availableSlots);
    }

    hasHeaderBackgroundChanged = (header, oldHeader) => {
        while (header !== null && oldHeader !== null) {
            if (
                header.visibleValues !== oldHeader.visibleValues ||
                header.hideGrid !== oldHeader.hideGrid
            ) {
                return true;
            }
            if (!_.isEqual(header.getSections(), oldHeader.getSections())) {
                return true;
            }
            // eslint-disable-next-line no-param-reassign
            header = header.subheader;
            // eslint-disable-next-line no-param-reassign
            oldHeader = oldHeader.subheader;
        }

        // If one of the headers is not null, the number of subheaders has changed
        return header !== oldHeader;
    };

    registerKeyboardShortcuts() {
        const self = this;
        if (process.env.NODE_ENV === "development") {
            Mousetrap.bindWithHelp(
                "o",
                () => {
                    // eslint-disable-next-line no-console
                    console.log("Setting occlusion to", !self.state.useOcclusion);
                    self.setState({ useOcclusion: !self.state.useOcclusion });
                },
                undefined,
                "Toggle occlusion"
            );
        }
    }

    isAllAvailable(availability) {
        return _.some(
            availability,
            (slot) =>
                slot.end_time === TC.SECONDS_PER_DAY &&
                slot.start_time === 0 &&
                slot.week_days.length === TC.DAYS_PER_WEEK
        );
    }

    updateTimeSlots(slotData: TTimeSlot[], availableData: TTimeSlot[]) {
        const canvas = this.refs.timeSlots as HTMLCanvasElement;
        const context = canvas.getContext("2d")!;
        canvas.width = this.props.size.width;
        canvas.height = this.props.size.height;
        context.clearRect(0, 0, canvas.width, canvas.height);
        if (!this.props.visibleDates || this.props.visibleDates.length === 0) {
            return;
        }
        if (availableData && availableData.length > 0) {
            if (!this.isAllAvailable(availableData)) {
                context.rect(0, 0, canvas.width, canvas.height);
                context.fillStyle = "#e3e3e3";
                context.strokeStyle = "#b2b4b4";
                context.fill();

                context.lineWidth = 1;
                context.fillStyle = "rgba(255,255,255,1)";
                _.filter(
                    _.sortBy(
                        Utils.getTimeSlotsForEntries(
                            availableData,
                            true,
                            this.props.headerObjects,
                            this.props,
                            this.getStyleDescriptor.bind(this)
                        ),
                        "height"
                    ).reverse(),
                    (slot) => slot !== null
                ).forEach((slot) => {
                    context.clearRect(slot.left, slot.top, slot.width, slot.height);
                    context.beginPath();
                    context.moveTo(slot.left, slot.top);
                    context.lineTo(slot.left + slot.width, slot.top);
                    context.stroke();
                    context.beginPath();
                    context.moveTo(slot.left, slot.top + slot.height);
                    context.lineTo(slot.left + slot.width, slot.top + slot.height);
                    context.stroke();
                    //context.strokeRect(slot.left, slot.top, slot.width, slot.height);
                });
            }
            //context.setLineDash([3, 3]);
            context.lineWidth = 0.5;
            context.strokeStyle = "#717676";
            context.font = "12px sans-serif";

            const isVertical = this.props.yHeader.hasTime() || this.props.yHeader.hasTimePeriod();
            const sizeProp = isVertical ? "width" : "height";
            Utils.getTimeSlotsForEntries(
                slotData,
                false,
                this.props.headerObjects,
                this.props,
                this.getStyleDescriptor.bind(this)
            ).forEach((slot) => {
                if (slot[sizeProp] < SIZE_CUTOFF) {
                    // eslint-disable-next-line no-param-reassign
                    slot[sizeProp] = slot[sizeProp] - SMALL_MOD;
                } else {
                    // eslint-disable-next-line no-param-reassign
                    slot[sizeProp] = slot[sizeProp] - LARGE_MOD;
                }
                context.fillStyle = "rgba(210,212,212,0.3)";
                context.fillRect(slot.left + 1, slot.top, slot.width, slot.height);
                context.strokeRect(slot.left + 1, slot.top, slot.width, slot.height);
                context.fillStyle = "#000000";
                context.fillText(
                    slot.entry.endTimes[0].getMillenniumTime().format("HH:mm"),
                    slot.left + THREE,
                    slot.top + slot.height - THREE
                );
            });
        }
    }

    updateBackgroundText = () => {
        if (this.props.background === false) {
            return;
        }
        if (this.props.skipBackgroundText === true) {
            const canvas = this.refs.backgroundText as HTMLCanvasElement;
            const context = canvas.getContext("2d")!;
            canvas.width = this.props.size.width;
            canvas.height = this.props.size.height;
            context.clearRect(0, 0, canvas.width, canvas.height);
            return;
        }

        if (TimeEdit.calendarBackgroundTexts && TimeEdit.calendarBackgroundTexts.length > 0) {
            const canvas = this.refs.backgroundText as HTMLCanvasElement;
            if (!canvas) {
                return;
            }
            const context = canvas.getContext("2d")!;
            canvas.width = this.props.size.width;
            canvas.height = this.props.size.height;
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.font = "128px sans-serif";
            context.fillStyle = "#eeaaaa";
            let yPos = 148;
            const yPosOffset = 72;
            TimeEdit.calendarBackgroundTexts.forEach((text, index) => {
                if (index > 0) {
                    context.font = "64px sans-serif";
                }
                const txtSize = context.measureText(text);
                // eslint-disable-next-line no-magic-numbers
                context.fillText(text, canvas.width / 2 - txtSize.width / 2, yPos);
                yPos += yPosOffset;
            });
        }
    };

    updateSoftAvailability = (availabilityData) => {
        const canvas = this.refs.softAvailability as HTMLCanvasElement;
        const context = canvas.getContext("2d")!;
        canvas.width = this.props.size.width;
        canvas.height = this.props.size.height;
        context.clearRect(0, 0, canvas.width, canvas.height);
        if (
            !this.props.visibleDates ||
            this.props.visibleDates.length === 0 ||
            this.props.isAbsoluteAvailability
        ) {
            return;
        }
        if (availabilityData && availabilityData.length > 0) {
            context.rect(0, 0, canvas.width, canvas.height);
            context.strokeStyle = "#C4B311";
            context.fillStyle = "rgb(255,246,210)";
            const colorDef = this.getColorDefBySignature("no-availability");
            if (colorDef) {
                context.fillStyle = `#${colorDef.baseColor}`;
                context.strokeStyle = `#${colorDef.borderColor}`;
            }

            context.fill();
            availabilityData
                .filter((slot) => slot.model.hasTime())
                .forEach((slot) => {
                    slot.styles.forEach((size) => {
                        if (size && size.width > 0 && size.height > 0) {
                            if (this.props.isAbsoluteAvailability) {
                                return;
                            }
                            context.clearRect(size.left, size.top, size.width, size.height);
                        }
                    });
                });
        }
    };

    updateAvailability = (availabilityData) => {
        const canvas = this.refs.absoluteAvailability as HTMLCanvasElement;
        const context = canvas.getContext("2d")!;
        canvas.width = this.props.size.width;
        canvas.height = this.props.size.height;
        context.clearRect(0, 0, canvas.width, canvas.height);
        if (
            !this.props.visibleDates ||
            this.props.visibleDates.length === 0 ||
            !this.props.isAbsoluteAvailability
        ) {
            return;
        }
        if (availabilityData && availabilityData.length > 0) {
            context.rect(0, 0, canvas.width, canvas.height);
            context.strokeStyle = "#C4B311";
            context.fillStyle = "rgb(255,246,210)";
            const colorDef = this.getColorDefBySignature("no-availability");
            if (colorDef) {
                context.fillStyle = `#${colorDef.baseColor}`;
                context.strokeStyle = `#${colorDef.borderColor}`;
            }

            context.fill();
            availabilityData
                .filter((slot) => slot.model.hasTime())
                .forEach((slot) => {
                    slot.styles.forEach((size) => {
                        if (size && size.width > 0 && size.height > 0) {
                            if (this.props.isAbsoluteAvailability) {
                                context.clearRect(size.left, size.top, size.width, size.height);
                                context.beginPath();
                                context.moveTo(size.left, size.top);
                                context.lineTo(size.left + size.width, size.top);
                                context.stroke();
                                context.beginPath();
                                context.moveTo(size.left, size.top - 1 + size.height);
                                context.lineTo(size.left + size.width, size.top - 1 + size.height);
                                context.stroke();
                            }
                        }
                    });
                });
        }
    };

    updateBackground = () => {
        const canvas = this.refs.canvas as HTMLCanvasElement;
        if (!canvas) {
            return;
        }
        const context = canvas.getContext("2d")!;
        canvas.width = this.props.size.width;
        canvas.height = this.props.size.height;
        context.clearRect(0, 0, canvas.width, canvas.height);

        if (this.props.background === false) {
            return;
        }

        const headerLines = {};
        let maxDepth = 0;
        const calculateHeaderLines = function (header, startPos, endPos, isVertical, depth) {
            if (!headerLines.hasOwnProperty(depth)) {
                headerLines[depth] = [];
            }
            maxDepth = Math.max(maxDepth, depth);

            const isHeaderObject = typeof header === "object";
            const numLines = isHeaderObject ? header.visibleValues : header;
            const minSize = Math.floor((endPos - startPos) / numLines);
            let i,
                total = 0,
                cellSize;
            const minTimeSize = 10;
            for (i = 0; i < numLines; i++) {
                cellSize = Grid.getSizeFromIndexes(i, i + 1, numLines, endPos - startPos);
                if (isHeaderObject && header.subheader) {
                    calculateHeaderLines(
                        header.subheader,
                        startPos + total,
                        startPos + total + cellSize,
                        isVertical,
                        depth + 1
                    );
                } else if (
                    isHeaderObject &&
                    !header.hideGrid &&
                    header.hasTime() &&
                    minSize > minTimeSize
                ) {
                    const MIN_SIZE = 28;
                    const FEW_LINES = 2;
                    const MORE_LINES = 4;
                    const DEPTH = 5;
                    const numTimeLines = minSize < MIN_SIZE ? FEW_LINES : MORE_LINES;
                    calculateHeaderLines(
                        numTimeLines,
                        startPos + total,
                        startPos + total + cellSize,
                        isVertical,
                        DEPTH
                    );
                }

                const POS_OFFSET = 0.5;
                const position = POS_OFFSET + startPos + total;
                total += cellSize;

                if (header.hideGrid) {
                    continue;
                }

                const isSpecialLine = isHeaderObject && header.isNewSection(i);

                if (isVertical) {
                    headerLines[depth].push({
                        from: { x: position, y: 0 },
                        to: { x: position, y: canvas.height },
                        isSpecialLine,
                        isVertical: true,
                    });
                } else {
                    headerLines[depth].push({
                        from: { x: 0, y: position },
                        to: { x: canvas.width, y: position },
                        isSpecialLine,
                        isVertical: false,
                    });
                }
            }
        };

        calculateHeaderLines(this.props.xHeader, 0, canvas.width, true, 0);
        calculateHeaderLines(this.props.yHeader, 0, canvas.height, false, 0);

        const drawLine = function (line) {
            context.moveTo(line.from.x, line.from.y);
            context.lineTo(line.to.x, line.to.y);
        };

        const drawLines = function (lines, fn, color) {
            context.beginPath();
            lines.forEach(fn);
            context.strokeStyle = color;
            context.stroke();
            context.closePath();
        };

        let color;
        for (let depth = maxDepth; depth >= 0; depth--) {
            if (headerLines.hasOwnProperty(String(depth))) {
                color = LINE_COLORS[depth] || LINE_COLORS[LINE_COLORS.length - 1];
                const COLOR_OFFSET = 2;
                if (this.props.maxClusterDepth > 1 && depth > LINE_COLORS.length - COLOR_OFFSET) {
                    color = LINE_COLORS[LINE_COLORS.length - COLOR_OFFSET];
                }
                drawLines(headerLines[String(depth)], drawLine, color);
            }
        }

        // Draw special lines
        if (headerLines.hasOwnProperty("0")) {
            const specialLines = headerLines[0].filter((line) => line.isSpecialLine === true);
            drawLines(specialLines, drawLine, SPECIAL_LINE_COLOR);
        }
    };

    isNotInfo = (entry) => {
        if (entry.kind === EntryKind.INFO) {
            return false;
        }
        return true;
    };

    isNotPrivateOrInfo = (entry: EntryModel) => {
        if (entry.kind === EntryKind.INFO) {
            return false;
        }
        const isPrivate =
            entry.kind === EntryKind.OCCUPIED ||
            entry.kind === EntryKind.OCCUPIED_HIDE ||
            entry.isLocked(this.props.unlockedReservations);
        if (isPrivate) {
            return false;
        }
        return true;
    };

    getEntryClasses = (
        entry: EntryModel | AvailabilityEntry,
        styles,
        visibleCapacityReservationIds: number[] = []
    ): TEntryCssClasses => {
        const depth = this.props.getClusterValues(entry.periods).length;
        let multiSelection = false;
        const groupIds = _.flatten(this.props.selectionGroup.map((etr) => etr.reservationids));
        if (entry.reservationids) {
            multiSelection = groupIds.indexOf(entry.reservationids[0]) !== -1;
        }

        const isComplete =
            entry.kind === EntryKind.COMPLETE || entry.kind === EntryKind.GROUP_COMPLETE;

        return {
            calendarEntry: !entry.isAvailability,
            calendarAvailability: entry.isAvailability,
            complete: isComplete,
            incomplete: entry.incomplete === true && !entry.isRequest(),
            preliminary: entry.status === ReservationConstants.STATUS.PRELIMINARY,
            planned: entry.status === ReservationConstants.STATUS.PLANNED,
            requested: entry.status === ReservationConstants.STATUS.REQUESTED,
            denied: entry.status === ReservationConstants.STATUS.REJECTED,
            standard: _.contains(
                [
                    EntryKind.RESERVATION,
                    EntryKind.COMPLETE,
                    EntryKind.GROUP,
                    EntryKind.GROUP_COMPLETE,
                ],
                entry.kind
            ),
            obstacle: entry.kind === EntryKind.OBSTACLE,
            info: entry.kind === EntryKind.INFO,
            membership: entry.membership,
            private:
                entry.kind === EntryKind.OCCUPIED ||
                entry.kind === EntryKind.OCCUPIED_HIDE ||
                entry.isLocked(this.props.unlockedReservations),
            occupiedGroup: entry.kind === EntryKind.OBSTACLE_GROUP,
            backgroundEntry: entry.occupied === false && !entry.overlapView,
            ghost: entry.kind === EntryKind.NONE,
            external: entry.kind === EntryKind.EXTERNAL,
            xStartPartial: styles.cutoffLeft,
            yStartPartial: styles.cutoffTop,
            xEndPartial: styles.cutoffRight,
            yEndPartial: styles.cutoffBottom,
            partialTimeMatch: entry.isPartial,
            selectedGroup: entry.grouped,
            fullCluster:
                EntryModel.isBlue(entry) &&
                this.props.maxClusterDepth > 1 &&
                entry.reservationids?.length === depth,
            partialCluster:
                EntryModel.isBlue(entry) &&
                this.props.maxClusterDepth > 1 &&
                entry.reservationids &&
                entry.reservationids.length > 1 &&
                entry.reservationids.length < depth,
            small: styles.width < MIN_WIDTH_FOR_TEXT || styles.height < MIN_HEIGHT_FOR_TEXT,
            collidesWithReality: entry.collidesWithReality,
            multiSelection,
            hasColor: this.props.colorTypes.length > 0,
            isPadding: entry.isPadding,
            isSize: entry.hasSize(),
            sizeInCapacity:
                entry.hasSize() &&
                Boolean(entry.capacityReservationId) &&
                visibleCapacityReservationIds.includes(entry.capacityReservationId!),
        } as TEntryCssClasses;
    };

    getStyleDescriptor = (
        entry: EntryModel | AvailabilityEntry,
        sideBySideEntries = {},
        visibleCapacityReservationIds: number[] = []
    ): React.CSSProperties => {
        const stylesArray: React.CSSProperties[] = [];
        let currentXHeader: Header | null = this.props.xHeader;
        let currentYHeader: Header | null = this.props.yHeader;

        let width = this.props.size.width;
        let height = this.props.size.height;

        let xPath = `${currentXHeader.getId()}`;
        let yPath = `${currentYHeader.getId()}`;

        let isSideBySideX = false;
        let isSideBySideY = false;

        while (currentYHeader || currentXHeader) {
            const style: React.CSSProperties = {
                left: undefined,
                width: undefined,
                top: undefined,
                height: undefined,
            };
            const shouldIncludeSizeEntry =
                !entry.hasSize() || (entry.hasSize() && !this.props.isSizeMode);
            // !entry.hasSize() || entry.hasSize();

            if (currentXHeader && currentXHeader.visibleValues > 0) {
                const index = currentXHeader.indexOf(entry, true);
                if (currentXHeader.isSideBySideAtIndex(index)) {
                    isSideBySideX = true;
                }
                const indexPath = `${xPath}:${index}`;
                if (index > -1 && entry !== undefined) {
                    xPath = indexPath;
                }
                if (index > -1 && entry !== undefined && isSideBySideX) {
                    if (!sideBySideEntries[indexPath]) {
                        // eslint-disable-next-line no-param-reassign
                        sideBySideEntries[indexPath] = [];
                    }
                    if (this.isNotInfo(entry) && shouldIncludeSizeEntry) {
                        sideBySideEntries[indexPath].push(entry);
                    }
                }
                style.left = Grid.getPositionFromIndex(index, currentXHeader.visibleValues, width);
                style.width = Grid.getSizeFromIndexes(
                    index,
                    currentXHeader.lastIndexOf(entry, true),
                    currentXHeader.visibleValues,
                    width
                );
            }
            if (currentYHeader && currentYHeader.visibleValues > 0) {
                const index = currentYHeader.indexOf(entry, true);
                if (currentYHeader.isSideBySideAtIndex(index)) {
                    isSideBySideY = true;
                }
                const indexPath = `${yPath}:${index}`;
                if (index > -1 && entry !== undefined) {
                    yPath = indexPath;
                }
                if (index > -1 && entry !== undefined && isSideBySideY) {
                    if (!sideBySideEntries[indexPath]) {
                        // eslint-disable-next-line no-param-reassign
                        sideBySideEntries[indexPath] = [];
                    }
                    if (this.isNotInfo(entry) && shouldIncludeSizeEntry) {
                        sideBySideEntries[indexPath].push(entry);
                    }
                }
                style.top = Grid.getPositionFromIndex(index, currentYHeader.visibleValues, height);
                style.height = Grid.getSizeFromIndexes(
                    index,
                    currentYHeader.lastIndexOf(entry, true),
                    currentYHeader.visibleValues,
                    height
                );
            }

            stylesArray.push(style);
            width = stylesArray[stylesArray.length - 1].width || 0;
            height = stylesArray[stylesArray.length - 1].height || 0;
            currentXHeader = currentXHeader ? currentXHeader.subheader : null;
            currentYHeader = currentYHeader ? currentYHeader.subheader : null;
            if (currentXHeader) {
                xPath = `${xPath}:${currentXHeader.getId()}`;
            }
            if (currentYHeader) {
                yPath = `${yPath}:${currentYHeader.getId()}`;
            }
        }

        let style = Utils.getEntryStyleFromArray(
            stylesArray,
            this.props.size.width,
            this.props.size.height
        );
        if (style.width <= 0 || style.height <= 0) {
            return null;
        }

        style = _.extend(style, this.getEntryColorStyle(entry));

        // Stretch entries over border
        style.width += BORDER_STRETCH;
        style.height += BORDER_STRETCH;
        style.identifier = Utils.getIdentifier(entry);
        return style;
    };

    getColorDef = (colorId: string | number): string => {
        if (colorId === "none") {
            return this.getColorDefBySignature(colorId);
        }
        return _.find(this.context.colorDefs, (def) => def.id === colorId);
    };

    getColorDefBySignature = (signature: string) =>
        _.find(this.context.colorDefs, (def) => def.signature === signature);

    getColor = (colorDef, colorProperty, isInBackground = false) => {
        const isBorderProperty = colorProperty.toLowerCase().indexOf("border") !== -1;
        if (!colorDef) {
            return isInBackground ? `rgba(243,243,243,0.8)` : `rgb(212,212,210)`;
        }
        const r = colorDef[`${colorProperty}-r`];
        const g = colorDef[`${colorProperty}-g`];
        const b = colorDef[`${colorProperty}-b`];
        const DEF_OP = 0.8;
        const opacity = isBorderProperty ? 1 : DEF_OP;
        return isInBackground
            ? `rgba(${r * BG_MULTIPLIER},${g * BG_MULTIPLIER},${b * BG_MULTIPLIER},${opacity})`
            : `rgb(${r},${g},${b})`;
    };

    getTextColor = (colorDef, colorProperty, isInBackground: boolean) => {
        if (!colorDef) {
            return isInBackground ? `rgba(0,0,0,0.8)` : `rgb(0,0,0)`;
        }
        const r = colorDef[`${colorProperty}-r`];
        const g = colorDef[`${colorProperty}-g`];
        const b = colorDef[`${colorProperty}-b`];
        return isInBackground ? `rgba(${r},${g},${b},0.8)` : `rgb(${r},${g},${b})`;
    };

    getEntryColorStyle = (entry: EntryModel) => {
        const isComplete =
            entry.kind === EntryKind.COMPLETE || entry.kind === EntryKind.GROUP_COMPLETE;

        const isMembership = entry.membership;

        const isInBackground = entry.occupied === false && !(entry as any).overlapView;

        const isCapacity = this.props.isSizeMode && entry.capacity !== undefined;
        // Standard could be removed if we are OK returning nothing meaningful if
        // no color definitions are available (I.E. the server is dead?)
        const opacity = isInBackground ? "0.8" : "1.0";
        const standard: React.CSSProperties = {
            backgroundColor: isComplete
                ? `rgba(77,116,172,${opacity})`
                : `rgba(210,210,210,${opacity})`,
            border: isInBackground ? "1px solid #bb2b4b4" : "1px solid rgba(150,150,150)",
        };

        if (this.props.colorTypes.length > 0) {
            standard.backgroundColor = isComplete
                ? `rgba(190,190,190,${opacity})`
                : `rgba(210,210,210,${opacity})`;
            standard.border = isInBackground ? "1px solid #bb2b4b4" : "1px solid rgba(150,150,150)";
            if (isInBackground) {
                standard.color = "gray";
            }
        }

        // A entry should not present as capacity if it has a size, even if that size is 0
        if (isCapacity && (entry.size === undefined || entry.size === null || entry.size === -1)) {
            if (isCapacity && (entry.remainingCapacity || 0) < this.props.fluffySize) {
                return {
                    backgroundColor: "rgba(255, 246, 175, 0.5)",
                    border: "1px solid rgba(75,75,75)",
                };
            }
            return {
                backgroundColor: "rgba(250,250,250, 0.5)",
                border: "1px solid rgba(75,75,75)",
            };
        }

        const colors: string[] = entry.colors && entry.colors.length > 0 ? entry.colors : ["none"];

        if (colors && colors.length > 0) {
            let colorDefs: string[] | any[] = _.filter(
                colors.map((color) => this.getColorDef(color)),
                (clr) => clr !== undefined
            );
            if (colors.length > 1) {
                colorDefs = []
                    .concat([this.getColorDef(MULTI_COLOR_ID)] as any)
                    .concat(colorDefs as any);
            }
            if (colorDefs[0] === undefined) {
                // eslint-disable-next-line no-undef
                mixpanel.track("No color def found", { colorDefs, colors });
            }
            const bgColor = this.getColor(
                colorDefs[0],
                isMembership ? "membershipColor" : isComplete ? "currentColor" : "baseColor",
                isInBackground
            );
            const result: React.CSSProperties = {
                borderColor: isMembership
                    ? this.getColor(colorDefs[0], "membershipBorderColor")
                    : isComplete
                    ? this.getColor(colorDefs[0], "currentBorderColor", isInBackground)
                    : this.getColor(colorDefs[0], "borderColor", isInBackground),
                backgroundColor: bgColor,
                color: isMembership
                    ? `#${colorDefs[0].membershipTextColor}`
                    : isComplete
                    ? `#${colorDefs[0].currentTextColor}`
                    : `#${colorDefs[0].textColor}`,
            };
            if (isInBackground && entry.kind !== EntryKind.INFO) {
                const textProperty = isMembership
                    ? "membershipTextColor"
                    : isComplete
                    ? "currentTextColor"
                    : "textColor";
                result.color = this.getTextColor(colorDefs[0], textProperty, isInBackground);
            }
            if (colorDefs.length > 0) {
                if (colorDefs[0].basePattern === "striped") {
                    let stripeOpacity = "0.5";
                    if (isComplete) {
                        stripeOpacity = "0.3";
                    }
                    if (isInBackground) {
                        stripeOpacity = "0.7";
                    }
                    result.backgroundImage = `repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(238, 238, 238, ${stripeOpacity}) 10px,  rgba(238,238,238, ${stripeOpacity}) 20px)`;
                }
                if (colorDefs[0].basePattern === "broad-striped") {
                    result.backgroundImage =
                        "repeating-linear-gradient(30deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 30px, transparent 0, transparent 60px)";
                }
                if (colorDefs[0].basePattern === "multi-color") {
                    const secondColor = this.getColor(
                        colorDefs[0],
                        isMembership
                            ? "secondMembershipColor"
                            : isComplete
                            ? "secondCurrentColor"
                            : "secondBaseColor",
                        isInBackground
                    );
                    result.backgroundImage = `radial-gradient(${secondColor} 20%, transparent 13%), radial-gradient(${secondColor} 20%, transparent 13%)`;
                    result.backgroundSize = "12px 12px";
                    result.backgroundPosition = "0 0, 6px 6px";
                }
                return result;
            }
        }

        return standard;
    };

    getSubSlots = () => {
        if (!this.props.availability) {
            return [];
        }
        const slots: AvailabilityEntry[] = [...this.props.availability];
        slots.sort((a, b) => b.getLength() - a.getLength());

        return slots.map((slot) => {
            // Split entries by days
            const diffDays =
                slot.endTimes[0].getMillenniumDate().getDayNumber() -
                slot.startTimes[0].getMillenniumDate().getDayNumber();
            const subSlots = _.range(0, diffDays + 1).map((i) => {
                const subSlot = _.safeClone(slot);

                if (i > 0) {
                    subSlot.startTimes = subSlot.startTimes.map((time) =>
                        time.addDays(i).getStartOfDay()
                    );
                }
                if (i < diffDays) {
                    subSlot.endTimes = subSlot.startTimes.map((time) =>
                        time.getEndOfDay().addSeconds(-1)
                    );
                }

                return subSlot;
            });

            const styles = _.compact(
                subSlots.map((subSlot) => {
                    try {
                        return this.getStyleDescriptor(subSlot);
                    } catch (e) {
                        console.warn("Could not get style descriptor for subSlot", e);
                        return null;
                    }
                })
            );

            const classList = styles.map((style) => this.getEntryClasses(slot, style, []));

            return {
                model: slot,
                styles,
                classes: classList,
            };
        }, this);
    };

    _getSubentries = (
        entries: EntryModel[] | null = [...this.props.entries],
        visibleCapacityReservationIds: number[],
        isVertical: boolean
    ): TEntryModelWithStyles[] => {
        const hasTime =
            (this.props.yHeader as Header).hasTime() || (this.props.xHeader as Header).hasTime();
        const allHeaders: Header[] = this.props.yHeader
            .getHeaders()
            .concat(this.props.xHeader.getHeaders());
        const hasDate = _.some(allHeaders, (header) => header instanceof DateHeader);
        const periodHeaders: PeriodHeader[] = _.filter(
            allHeaders,
            (header) => header instanceof PeriodHeader
        ) as PeriodHeader[];
        const hasPeriods = periodHeaders !== undefined && periodHeaders.length > 0;
        const weekdayHeaders: WeekdayHeader[] = allHeaders.filter(
            (header) => header instanceof WeekdayHeader
        ) as WeekdayHeader[];
        const hasWeekdayHeaders = weekdayHeaders !== undefined && weekdayHeaders.length > 0;
        const weekHeaders: WeekHeader[] = allHeaders.filter(
            (header) => header instanceof WeekHeader
        ) as WeekHeader[];
        const hasWeekHeaders = weekHeaders !== undefined && weekHeaders.length > 0;
        let weeks = [];
        if (hasWeekHeaders) {
            weeks = weekHeaders[0].getVisibleValues(); // You can only have one week header
        }
        const splitOnDays = hasTime || !hasDate;

        const sideBySideEntries = {};
        const allSubEntries = _.compact(
            entries?.map((entry) => {
                let subentries = entry.getSubentries(this.props.headerObjects || [], splitOnDays);

                // When a calendar does not have a date provider of some kind, it provides a
                // fallback date to be used as a filter. This ensures that calendars without date
                // providers do not show multiple subentries on the same day.
                if (this.props.fallbackDate && subentries.length > 1) {
                    subentries = subentries.filter((subentry) => {
                        const dayNumber = subentry.startTimes[0].getMillenniumDate().getDayNumber();
                        return (
                            dayNumber >= this.props.fallbackDate.getDayNumber() &&
                            dayNumber <= this.props.fallbackDate.getDayNumber()
                        );
                    });
                }

                if (subentries.length > entries.length) {
                    subentries = _.filter(
                        subentries,
                        (subEntry) =>
                            _.some(subEntry.startTimes, (sT) =>
                                _.some(
                                    this.props.visibleDates,
                                    (vD) =>
                                        vD.getDayNumber() === sT.getMillenniumDate().getDayNumber()
                                )
                            ) ||
                            _.some(subEntry.endTimes, (eT) =>
                                _.some(
                                    this.props.visibleDates,
                                    (vD) =>
                                        vD.getDayNumber() === eT.getMillenniumDate().getDayNumber()
                                )
                            )
                    );
                }

                if (hasWeekdayHeaders) {
                    weekdayHeaders.forEach((weekdayHeader) => {
                        subentries = subentries.filter(
                            (subentry) =>
                                weekdayHeader.getIndexOfDate(
                                    subentry.startTimes[0].getMillenniumDate(),
                                    true,
                                    weeks
                                ) !== -1
                        );
                    });
                }

                if (
                    hasPeriods &&
                    _.some(periodHeaders, (ph) => ph instanceof WeekdayPeriodHeader)
                ) {
                    const periodHeader = periodHeaders.find(
                        (ph) => ph instanceof WeekdayPeriodHeader
                    );
                    subentries = subentries.filter(
                        (subentry) =>
                            periodHeader?.getIndexOfDate(
                                subentry.startTimes[0].getMillenniumDate(),
                                true
                            ) !== -1
                    );
                }
                if (
                    hasPeriods &&
                    _.some(
                        periodHeaders,
                        (ph) => ph instanceof DatePeriodHeader || ph instanceof WeekPeriodHeader
                    )
                ) {
                    // Only keep subEntry if on a date included in the period
                    subentries = _.filter(
                        subentries,
                        (subEntry) => subEntry.periods && _.keys(subEntry.periods).length !== 0
                    );
                    subentries = _.filter(subentries, (subEntry) => {
                        const entryDayNumber = subEntry.startTimes[0]
                            .getMillenniumDate()
                            .getDayNumber();
                        const dateTimes = _.asArray(
                            PeriodHeader.getDateTimeFromPeriods(
                                periodHeaders,
                                periodHeaders.map((ph) => subEntry.periods[ph.getId()])
                            )
                        );
                        return _.some(
                            dateTimes,
                            (dateTime) =>
                                dateTime.getMillenniumDate().getDayNumber() === entryDayNumber
                        );
                    });
                }

                const styles = _.compact(
                    subentries.map((subentry) => {
                        try {
                            return this.getStyleDescriptor(
                                subentry,
                                sideBySideEntries,
                                visibleCapacityReservationIds
                            );
                        } catch (e) {
                            return null;
                        }
                    })
                );

                if (styles.length === 0) {
                    return null;
                }

                const classList = styles.map((style) =>
                    this.getEntryClasses(entry, style, visibleCapacityReservationIds)
                );

                const sizeProp = isVertical ? "width" : "height";
                classList.forEach((classes, index) => {
                    const style = styles[index];
                    if (!classes.private && !classes.info) {
                        if (style[sizeProp] < SIZE_CUTOFF) {
                            style[sizeProp] = style[sizeProp] - SMALL_MOD;
                        } else {
                            style[sizeProp] = style[sizeProp] - LARGE_MOD;
                        }
                    }
                    if (classes.private) {
                        const posProp = isVertical ? "left" : "top";
                        // eslint-disable-next-line no-magic-numbers
                        style[sizeProp] = style[sizeProp] - 2;
                        style[posProp] = style[posProp] + 1;
                    }
                    if (classes.selectedGroup || classes.hasGroup) {
                        if (style.width < NARROW_CUTOFF) {
                            // eslint-disable-next-line no-param-reassign
                            classes.narrowWidth = true;
                        }
                    }
                });

                return {
                    model: entry,
                    styles,
                    classes: classList,
                };
            })
        );
        Object.keys(sideBySideEntries).forEach(
            (key) =>
                (sideBySideEntries[key] = sideBySideEntries[key]
                    .map((entry) => {
                        const matchedSubEntry = _.find(allSubEntries, (etr) =>
                            this.isMatching(etr.model, entry, etr.matched)
                        );
                        if (matchedSubEntry) {
                            matchedSubEntry.matched = true;
                            matchedSubEntry.model = matchedSubEntry.model.clone();
                            matchedSubEntry.model.isSideBySide = true;
                            return Object.assign({}, matchedSubEntry, { subEntry: entry });
                        }
                        return {};
                    })
                    .filter((entry) => entry.model !== undefined))
        );
        Utils.adjustSideBySideEntries(sideBySideEntries, isVertical);
        return allSubEntries;
    };
    get getSubentries() {
        return this._getSubentries;
    }
    set getSubentries(value) {
        this._getSubentries = value;
    }

    isMatching = (entry: EntryModel, otherEntry, matched) => {
        if (matched === true) {
            return false;
        }
        if (!_.isEqual(entry.reservationids, otherEntry.reservationids)) {
            return false;
        }
        if (entry.reservationids.length === 0 || _.every(entry.reservationids, (id) => id === 0)) {
            return (
                entry.endTimes.map((dt) => dt.mts).join(".") +
                    entry.startTimes.map((dt) => dt.mts).join(".") ===
                otherEntry.endTimes.map((dt) => dt.mts).join(".") +
                    otherEntry.startTimes.map((dt) => dt.mts).join(".")
            );
        }
        return true;
    };

    requestTooltip = (entryKey: string | null, show: boolean, force = false) => {
        if (force) {
            this.setState({ isTooltipLocked: false });
        }

        let timeout = this._toolTipTimeout!;
        clearTimeout(timeout);
        if (show === true && this.state.tooltipVisible === true) {
            this.setState({ activeTooltip: entryKey });
            return;
        }
        if (show === false) {
            // eslint-disable-next-line no-param-reassign
            entryKey = null;
        }
        const self = this;
        if (!show) {
            this._toolTipTimeout = null;
            this.setState({ tooltipVisible: show, activeTooltip: entryKey });
            return;
        }
        const tooltipShowDelay = this.props.isConflictMode
            ? TOOLTIP_SHOW_DELAY_IN_CONFLICT_MODE
            : TOOLTIP_SHOW_DELAY;

        timeout = setTimeout(
            () => {
                self._toolTipTimeout = null;
                self.setState({ tooltipVisible: show, activeTooltip: entryKey });
            },
            show ? tooltipShowDelay : TOOLTIP_HIDE_DELAY
        );
        this._toolTipTimeout = timeout;
    };

    onEntryLayerMouseDown = (event: React.MouseEvent) => {
        clearTimeout(this._toolTipTimeout!);
        if (!this.state.isTooltipLocked) {
            this.setState({ tooltipVisible: false, activeTooltip: null });
        }
        if (this.props.onMouseDown) {
            // The layer for overlapping entries has no onMouseDown.
            this.props.onMouseDown(event);
        }
    };

    getVisibleReservationsInGroups(groupIds) {
        const result: number[][] = [];
        groupIds.forEach((groupId) => {
            (this.props.entries as EntryModel[]).forEach((entry) => {
                if (entry.groups.indexOf(groupId) !== -1) {
                    result.push(entry.reservationids);
                }
            });
        });
        return _.uniq(_.flatten(result));
    }

    createOcclusionMap(entries: TEntryModelWithStyles[], isVertical) {
        const map = new EntryMap(this.props.size.width, this.props.size.height);

        const visible: TEntryModelWithStyles[] = [];
        const invisible: TEntryModelWithStyles[] = [];
        [...entries].reverse().forEach((entry) => {
            entry.styles.forEach((style) => {
                const area = map.getArea(
                    isVertical ? style.left + 2 : style.left,
                    isVertical ? style.top : style.top + 2,
                    isVertical ? 1 : style.width,
                    isVertical ? style.height : 1
                );
                if (notAllOccluded(area)) {
                    // Draw the entry to the map, keep the entry as visible
                    // eslint-disable-next-line no-console
                    visible.push(entry);
                    map.setArea(style.left, style.top, style.width, style.height);
                } else {
                    invisible.push(entry);
                }
            });
        });
        visible.reverse();
        return { visible, invisible, map };
    }

    onEntryClick(
        entry: EntryModel,
        addToSelection = false,
        shiftKey = false,
        isEntryLocked: boolean
    ) {
        if (isEntryLocked) {
            this.props.onLockedEntryClick();
        } else {
            this.props.onEntryClick(entry, addToSelection, shiftKey);
        }
    }

    filterOutSizeChildEntries(
        entries: TEntryModelWithStyles[],
        visibleCapacityReservationIds: number[]
    ) {
        // Declare map to use later when entries need to find out if the have any "size entry children"
        const entrySizeChildrenMap: Map<number, TEntryModelWithStyles[]> = new Map();

        const filteredEntries = entries.filter((entry) => {
            const hasCapacityReservationId = Boolean(entry.model.capacityReservationId) === true;
            const isCapacityReservationIdPointingOnItself = entry.model.reservationids.includes(
                entry.model.capacityReservationId!
            );

            // Check if its a size child entry, and add it to a map, for future lookups.
            if (
                hasCapacityReservationId &&
                !isCapacityReservationIdPointingOnItself &&
                this.props.isSizeMode &&
                entry.model.hasSize()
            ) {
                this._addEntryToSizeChildrenMap(entrySizeChildrenMap, entry);
            }

            // Should this potential child size entry be filtered out?
            return (
                !this.props.isSizeMode ||
                !hasCapacityReservationId ||
                entry.model.reservationids.includes(entry.model.capacityReservationId!) ||
                !visibleCapacityReservationIds.includes(entry.model.capacityReservationId!)
            );
        });

        return { entrySizeChildrenMap, filteredEntries };
    }

    render() {
        const isVertical: boolean =
            this.props.yHeader.hasTime() || this.props.yHeader.hasTimePeriod();
        const baseEntries: EntryModel[] = this.props.paddingActive
            ? this.props.entries
            : this.props.entries.filter((entry) => !entry.isPadding);
        const visibleCapacityReservationIds = _.filter(
            [...baseEntries],
            (entry) => entry && Boolean(entry.capacity) && entry.capacity > 0
        )
            .map((entry) => entry.reservationids)
            .flat();

        let entryData = this.getSubentries(baseEntries, visibleCapacityReservationIds, isVertical);

        // Filter out size child entries into a map.
        const { filteredEntries, entrySizeChildrenMap } = this.filterOutSizeChildEntries(
            entryData,
            visibleCapacityReservationIds
        );
        entryData = filteredEntries;

        let foregroundEntries = _.isEqual(entryData, this._prevEntries)
            ? this._prevForegroundEntries
            : [];
        let backgroundEntries = _.isEqual(entryData, this._prevEntries)
            ? this._prevBackgroundEntries
            : [];

        if (!_.isEqual(entryData, this._prevEntries)) {
            if (this.state.useOcclusion) {
                const mapResult = this.createOcclusionMap(
                    entryData.filter((entry) => entry.model.occupied === true || entry.model.size),
                    isVertical
                );
                const finalMap = mapResult.map;
                foregroundEntries = mapResult.visible;

                // Add availableSlots
                if (this.props.isAbsoluteAvailability) {
                    const map = new EntryMap(this.props.size.width, this.props.size.height);
                    const slots = this.getSubSlots();
                    if (
                        this.props.visibleDates &&
                        this.props.visibleDates.length > 0 &&
                        slots &&
                        slots.length > 0
                    ) {
                        map.setArea(0, 0, this.props.size.width, this.props.size.height);
                        slots
                            .filter((slot) => slot.model.hasTime?.())
                            .forEach((slot) => {
                                slot.styles.forEach((size) => {
                                    if (size && Number(size.width) > 0 && Number(size.height) > 0) {
                                        if (this.props.isAbsoluteAvailability) {
                                            map.clearArea(
                                                size.left,
                                                size.top,
                                                size.width,
                                                size.height
                                            );
                                        }
                                    }
                                });
                            });
                    }
                    // combine maps
                    finalMap.addMap(map);
                }
                if (this.props.availableSlots) {
                    const availableData = this.props.availableSlots;
                    //const slotData = this.props.timeSlots;
                    const map = new EntryMap(this.props.size.width, this.props.size.height);
                    if (
                        this.props.visibleDates &&
                        this.props.visibleDates.length > 0 &&
                        availableData &&
                        availableData.length > 0
                    ) {
                        if (!this.isAllAvailable(availableData)) {
                            map.setArea(0, 0, this.props.size.width, this.props.size.height);
                            _.filter(
                                _.sortBy(
                                    Utils.getTimeSlotsForEntries(
                                        availableData,
                                        true,
                                        this.props.headerObjects,
                                        this.props,
                                        this.getStyleDescriptor.bind(this)
                                    ),
                                    "height"
                                ).reverse(),
                                (slot) => slot !== null
                            ).forEach((slot) => {
                                map.clearArea(slot.left, slot.top, slot.width, slot.height);
                            });
                        }
                    }
                    finalMap.addMap(map);
                }
                backgroundEntries = entryData
                    .filter((entry) => entry.model.occupied === false)
                    .filter((entry) => {
                        return entry.styles
                            .map((style) => {
                                const area = finalMap.getArea(
                                    isVertical ? style.left + 2 : style.left,
                                    isVertical ? style.top : style.top + 2,
                                    isVertical ? 1 : style.width,
                                    isVertical ? style.height : 1
                                );
                                if (notAllOccluded(area)) {
                                    return true;
                                }
                                return false;
                            })
                            .some((isVisible) => isVisible === true);
                    });
            } else {
                foregroundEntries = entryData.filter(
                    (entry) => entry.model.occupied === true || entry.model.size
                );
                backgroundEntries = entryData.filter(
                    (entry) => entry.model.occupied === false && !entry.model.size
                );
            }
        }

        const borderEntries: TEntryModelWithStyles[] | undefined = _.isEqual(
            entryData,
            this._prevEntries
        )
            ? this._prevBorderEntries
            : backgroundEntries
                  ?.filter((entry) => entry.model.kind !== EntryKind.INFO)
                  .filter((etr, index, entries) => !Utils.isObscured(etr, entries));

        this._prevEntries = entryData;
        this._prevForegroundEntries = foregroundEntries;
        this._prevBackgroundEntries = backgroundEntries;
        this._prevBorderEntries = borderEntries;

        let dragElements: React.JSX.Element[] | null = null;
        if (this.props.dragElements && this.props.dragElements.length > 0) {
            const dragEntries = this.props.dragElements
                .sort((first, second) => {
                    if (first.kind > second.kind) {
                        return -1;
                    }
                    if (first.kind < second.kind) {
                        return 1;
                    }
                    return 0;
                })
                .reverse()
                .reduce((list, entry, index, allEntries) => {
                    const remainingEntries = allEntries.slice(index + 1);
                    if (
                        _.some(remainingEntries, (otherEntry) =>
                            EntryModel.timeEquals(otherEntry, entry)
                        )
                    ) {
                        return list;
                    }
                    return list.concat(entry);
                }, [] as EntryModel[])
                .reverse();
            dragElements = this.getSubentries(
                dragEntries,
                visibleCapacityReservationIds,
                isVertical
            ).map((entry, index) => this._renderEntry(entry, index, isVertical));
        }

        return (
            <div
                className="entryLayer"
                style={this.props.size}
                onMouseDown={this.onEntryLayerMouseDown}
                onMouseUp={this.props.onMouseUp}
                onTouchStart={this.onEntryLayerMouseDown}
                onContextMenu={this.onEntryLayerMouseDown}
            >
                <canvas ref="backgroundText" className="availabilityCanvas" />
                <canvas ref="softAvailability" className="availabilityCanvas" />
                {backgroundEntries?.map((entry, index) =>
                    this._renderEntryWithSizeChildren(
                        entry,
                        entrySizeChildrenMap,
                        index,
                        isVertical,
                        false,
                        borderEntries
                    )
                )}
                <canvas ref="absoluteAvailability" className="availabilityCanvas" />
                <canvas ref="canvas" className="availabilityCanvas" />
                {borderEntries.map((entry, index) =>
                    this._renderEntry(entry, index, isVertical, true)
                )}
                <canvas ref="timeSlots" className="availabilityCanvas" />
                {foregroundEntries?.map((entry, index) =>
                    this._renderEntryWithSizeChildren(
                        entry,
                        entrySizeChildrenMap,
                        index,
                        isVertical
                    )
                )}
                {dragElements}
            </div>
        );
    }

    _addEntryToSizeChildrenMap(
        sizeChildren: Map<number, TEntryModelWithStyles[]>,
        entry: TEntryModelWithStyles
    ) {
        const prevEntries = sizeChildren.get(entry.model.capacityReservationId!);

        if (prevEntries) {
            const newEntries = [...prevEntries, entry]
                .sort((a, b) => (a.model.reservationids[0] > b.model.reservationids[0] ? 1 : -1))
                .map((e, index) => ({
                    ...e,
                    styles: [...e.styles, { order: index }], // Set css order style with sorted order.
                }));
            sizeChildren.set(entry.model.capacityReservationId!, newEntries);
        } else {
            sizeChildren.set(entry.model.capacityReservationId!, [entry]);
        }
    }

    _renderEntryWithSizeChildren = (
        entry: TEntryModelWithStyles,
        entrySizeChildrenMap: Map<number, TEntryModelWithStyles[]>,
        index: number,
        isVertical: boolean,
        borderAndTextOnly = false,
        textlessEntries: any[] = []
    ) => {
        const sizeEntries =
            entry.model.reservationids.length === 1
                ? entrySizeChildrenMap.get(entry.model.reservationids[0]) ?? []
                : [];

        const sizeEntriesChildren: React.ReactElement[] = sizeEntries.map((sizeEntry, index) => {
            const sizeEntryStyle: React.CSSProperties =
                sizeEntry.styles?.reduce(
                    (prevStyles, styles) => ({ ...prevStyles, ...styles }),
                    {}
                ) ?? {};
            const [entryStyle] = entry.styles;

            // Override styles for child size entries, since they rely on flexbox for some style properties
            const overriddenStyles: React.CSSProperties = {
                ...sizeEntryStyle,
                position: "relative",
                left: isVertical
                    ? 0
                    : ((sizeEntryStyle.left as number) ?? 0) - ((entryStyle?.left as number) ?? 0), // subtract parent left
                order: sizeEntryStyle.order,
                top: isVertical
                    ? ((sizeEntryStyle.top as number) ?? 0) - ((entryStyle?.top as number) ?? 0) // subtract parent top
                    : 0,
                height: isVertical
                    ? sizeEntryStyle.height
                    : ((entryStyle?.height as number) ?? 0) / sizeEntries.length - HEIGHT_MODIFIER, // calculate the height manually, this could be entirely flexbox with some effort
            };
            // Set overriden styles and isSideBySide to true
            sizeEntry.styles = [overriddenStyles];
            sizeEntry.model = sizeEntry.model.clone();
            sizeEntry.model.isSideBySide = true;

            return this._renderEntry(sizeEntry, index, isVertical, false);
        });

        return this._renderEntry(
            entry,
            index,
            isVertical,
            borderAndTextOnly,
            textlessEntries,
            sizeEntriesChildren
        );
    };

    _renderEntry = (
        entry: TEntryModelWithStyles,
        index: number,
        isVertical: boolean,
        borderAndTextOnly = false,
        textlessEntries: any[] = [],
        sizeEntries: React.ReactElement[] = []
    ) => {
        const key = Utils.getEntryKey(entry.model, index);
        const isActiveInfoEntry =
            this.props.infoEntryReservationIds.length > 0 &&
            _.intersection(entry.model.reservationids, this.props.infoEntryReservationIds)
                .length === this.props.infoEntryReservationIds.length;

        const isEntryLocked = entry.model.isLocked(this.props.unlockedReservations);
        let disableMenu = false;
        // TODO: Disable menu again if the entry contains group?
        // TODO: Adjust menu options for background entries in group mode with new reservations? Should they have only the add to group option?
        if (!(this.context.useNewReservationGroups && this.props.isGroupMode)) {
            disableMenu =
                this.props.spotlightDate &&
                !_.some(entry.model.startTimes, (time) =>
                    time.getMillenniumDate().isSameDayAs(this.props.spotlightDate)
                );
        }

        return (
            <Entry
                flags={this.props.flags}
                isTextless={entry.model.isPadding || textlessEntries.indexOf(entry) !== -1}
                borderAndTextOnly={borderAndTextOnly}
                renderTooltip={this.props.renderTooltip}
                unrenderTooltip={this.props.unrenderTooltip}
                isTooltipLocked={this.state.isTooltipLocked}
                isInOverlapView={this.props.isOverlapView}
                onClick={this.onEntryClick.bind(this)}
                onObjectInfo={this.props.onObjectInfo}
                selectionGroup={this.props.selectionGroup}
                selectionContainsGroups={this.props.selectionContainsGroups}
                isGroupMode={this.props.isGroupMode}
                addToGroup={this.props.addToGroup}
                addReservation={this.props.addReservationToTime}
                createGroup={this.props.createGroup}
                createGroupFromEntry={this.props.createGroupFromEntry}
                deleteGroup={this.props.deleteGroup}
                editGroup={this.props.editGroup}
                disableMenu={disableMenu}
                isLegalGroup={this.props.isLegalGroup}
                isLayerActive={this.props.isActive}
                activeLayer={this.props.activeLayer}
                selectedReservationIds={this.props.selectedReservationIds}
                getClusterValues={this.props.getClusterValues}
                clusterKind={this.props.clusterKind}
                isWeekCluster={this.props.isWeekCluster}
                isTooltipVisible={key === this.state.activeTooltip}
                onCopy={this.props.onEntryCopy}
                requestTooltip={this.requestTooltip.bind(this)}
                layerSize={this.props.size}
                entryKey={key}
                onInfoOpen={this.props.onEntryInfoOpen}
                onEditExceptions={this.props.onEditReservationExceptions}
                key={key}
                ref={key}
                data={entry.model}
                isVertical={isVertical}
                styles={entry.styles}
                classes={entry.classes}
                onDragStart={isEntryLocked ? _.noop : this.props.onEntryDragStart}
                enableEditMode={this.props.enableEditMode}
                emailReservation={this.props.emailReservation}
                isActiveInfoEntry={isActiveInfoEntry}
                moveToWaitingList={this.props.moveToWaitingList}
                onDelete={this.props.onEntryDelete}
                showOverlappingEntries={this.props.showOverlappingEntries}
                showOverlappingEntriesInList={this.props.showOverlappingEntriesInList}
                readOnlyCalendar={this.props.readOnly}
                onDynamicReservationIdsChanged={this.props.onDynamicReservationIdsChanged}
                openStaticReservationListAdd={this.props.openStaticReservationListAdd}
                templateKind={this.props.templateKind}
                getVisibleReservationsInGroups={this.getVisibleReservationsInGroups.bind(this)}
                isConflictMode={this.props.isConflictMode}
                isSizeMode={this.props.isSizeMode}
                isEntryLocked={isEntryLocked}
                sizeEntries={sizeEntries}
                parentCalendarElemRef={this._parentCalendarElemRef}
                fluffySize={this.props.fluffySize}
                splitReservation={this.props.splitReservation}
            />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.xHeader !== nextProps.xHeader ||
            this.props.yHeader !== nextProps.yHeader ||
            this.props.maxClusterDepth !== nextProps.maxClusterDepth ||
            this.props.getClusterValues !== nextProps.getClusterValues ||
            !_.isEqual(this.props.entries, nextProps.entries) ||
            !_.isEqual(this.props.infoEntryReservationids, nextProps.infoEntryReservationids) ||
            this.props.size.width !== nextProps.size.width ||
            this.props.size.height !== nextProps.size.height ||
            !_.isEqual(this.props.dragElements, nextProps.dragElements) ||
            this.props.isActive !== nextProps.isActive ||
            this.props.isAbsoluteAvailability !== nextProps.isAbsoluteAvailability ||
            !_.isEqual(this.props.availability, nextProps.availability) ||
            !_.isEqual(this.props.timeSlots, nextProps.timeSlots) ||
            !_.isEqual(this.props.visibleDates, nextProps.visibleDates) ||
            !_.isEqual(this.props.headerObjects, nextProps.headerObjects) ||
            !_.isEqual(this.props.visibleDates, nextProps.visibleDates) ||
            this.state.tooltipVisible !== nextState.tooltipVisible ||
            this.state.activeTooltip !== nextState.activeTooltip ||
            this.state.isTooltipLocked !== nextState.isTooltipLocked ||
            this.props.skipBackgroundText !== nextProps.skipBackgroundText ||
            this.props.paddingActive !== nextProps.paddingActive ||
            this.state.useOcclusion !== nextState.useOcclusion
        );
    }
}

export default EntryLayer;
