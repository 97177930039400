import { MillenniumDateTime } from "@timeedit/millennium-time";
import _ from "underscore";

export class AvailabilityEntry {
    startTimes: MillenniumDateTime[];
    endTimes: MillenniumDateTime[];
    objects: any[];
    clusterId: number;
    periodIds: number[];
    isAvailability: boolean;

    constructor(startTime?, endTime?, objects = [], clusterId = 0, periodIds = []) {
        this.startTimes = [startTime];
        this.endTimes = [endTime];
        this.objects = objects;
        this.clusterId = clusterId;
        this.periodIds = periodIds;
        this.isAvailability = true;
    }

    static create(data) {
        return new AvailabilityEntry(
            new MillenniumDateTime(data.begin || 0),
            new MillenniumDateTime(data.end || 0),
            data.objects,
            data.clusterId,
            data.periodIds
        );
    }

    getLength() {
        return this.endTimes[0].getMts() - this.startTimes[0].getMts() || 0;
    }

    hasTime() {
        return this.getLength() > 0;
    }

    hasSize() {
        return false;
    }

    equals(slot) {
        return (
            this.clusterId === slot.clusterId &&
            _.isEqual(this.periodIds, slot.periodIds) &&
            _.every(this.startTimes, (time, i) => time.equals(slot.startTimes[i])) &&
            _.every(this.endTimes, (time, i) => time.equals(slot.endTimes[i])) &&
            _.isEqual(this.objects, slot.objects)
        );
    }

    isLocked() {
        return false;
    }

    clone(): AvailabilityEntry {
        return Object.keys(this).reduce(
            (entry, key) => _.extend(entry, { [key]: this[key] }),
            new AvailabilityEntry()
        );
    }
}
