import React, { useState } from "react";

type TFieldValue = {
    id: number;
    values: string[];
};

type TSplitObjectResult = {
    adjustedSize: number;
    mandatoryFieldValues: TFieldValue;
    newExtId: string;
};

type TSplitObjectDialogProps = {
    object: any;
    fieldDefs: any[];
    initialSize: number;
    onDone: (TSplitObjectResult) => void;
};

const SplitObjectDialog = (props: TSplitObjectDialogProps) => {
    const [newExtId, setNewExtId] = useState(`${props.object.extid}-new`);
    const [oldObjectSize, setOldObjectSize] = useState<number>(props.initialSize);
    const [newObjectSize, setNewObjectSize] = useState<number>(0);
    const [fieldValues, setFieldValues] = useState<TFieldValue[]>(
        props.object.fields.map((field) => {
            return { id: field.id, values: [`${field.values[0]}-new`] };
        })
    );

    const updateSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setNewObjectSize(newSize);
        setOldObjectSize((props.object.size || 30) - newSize);
    };

    const updateOldSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setOldObjectSize(newSize);
        setNewObjectSize((props.object.size || 30) - newSize);
    };

    const updateFieldValue = (fieldId, value) => {
        const updatedFields = [...fieldValues];
        if (!updatedFields.find((field) => field.id === fieldId)) {
            updatedFields.push({ id: fieldId, values: [] });
        }
        const newFieldValues = updatedFields.map((fieldValue) => {
            if (fieldValue.id === fieldId) {
                return { ...fieldValue, values: [value] };
            }
            return fieldValue;
        });
        setFieldValues(newFieldValues);
    };

    const getValue = (fieldId) => {
        const fieldValue = fieldValues.find((fieldValue) => fieldValue.id === fieldId);
        return fieldValue ? fieldValue.values[0] : "";
    };

    const onDone = () => {
        props.onDone({
            adjustedSize: newObjectSize,
            mandatoryFieldValues: fieldValues,
            newExtId,
        });
    };

    const renderFieldRow = (fieldDef) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
                <span style={{ width: "192px" }}>{fieldDef.name}</span>
                <input
                    key={fieldDef.id}
                    type="text"
                    value={getValue(fieldDef.id)}
                    onChange={(event) => {
                        updateFieldValue(fieldDef.id, event.target.value);
                    }}
                />
            </div>
        );
    };
    // TODO onCancel which just closes, not invoking onDone
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", rowGap: "5px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                    Original external ID
                    <br />
                    <input value={props.object.extid} readOnly />
                </div>
                <div style={{ flexGrow: "1" }} />
                <div>
                    New external ID
                    <br />
                    <input
                        type="text"
                        value={newExtId}
                        onChange={(evt) => setNewExtId(evt.target.value)}
                    />
                </div>
            </div>
            <label>Size</label>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                    Old object
                    <br />
                    <input type="number" value={oldObjectSize} onChange={updateOldSize} />
                </div>
                <div style={{ flexGrow: "1" }}>
                    &nbsp;
                    <br />
                    <input
                        style={{ width: "98%" }}
                        type="range"
                        min="0"
                        max={props.object.size || 30}
                        value={newObjectSize}
                        onChange={updateSize}
                    />
                </div>
                <div>
                    New object
                    <br />
                    <input type="number" value={newObjectSize} onChange={updateSize} />
                </div>
            </div>
            <label>Unique fields</label>
            {props.fieldDefs
                .filter((fieldDef) => fieldDef.mandatory && fieldDef.unique)
                .map((fieldDef) => {
                    return renderFieldRow(fieldDef);
                })}
            {/*             <label>Mandatory fields</label>
            {props.fieldDefs
                .filter((fieldDef) => fieldDef.mandatory && !fieldDef.unique)
                .map((fieldDef) => {
                    return renderFieldRow(fieldDef);
                })} */}
            <div className="buttons btnGroup horizontal">
                <button className="modernButton" onClick={onDone}>
                    Cancel
                </button>
                <button className="modernButton" onClick={onDone}>
                    Split
                </button>
            </div>
        </div>
    );
};

export default SplitObjectDialog;
