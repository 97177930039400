import {
    MillenniumDate,
    MillenniumDateTime,
    MillenniumTime,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import Language from "../lib/Language";
import { Header } from "./Header";
import _ from "underscore";

export const TIME_HEADER_DEFAULT_VISIBLE_VALUES = 10;
const DEFAULT_FIRST_VALUE = 8;

export class TimeHeader extends Header {
    isContinuous: boolean;
    discreteStep: number;

    constructor(
        visibleValues = TIME_HEADER_DEFAULT_VISIBLE_VALUES,
        firstVisibleValue = DEFAULT_FIRST_VALUE,
        subheader?
    ) {
        super(visibleValues, firstVisibleValue, subheader, "TimeHeader");
        this.isContinuous = true;
        this.discreteStep = 3600;
    }

    getIndexOfTime(time, onlyVisible) {
        const timeNumber = time.getTimeNumber();
        if (!onlyVisible) {
            return (
                (this.getValues().length * (timeNumber - this.getStartTime())) /
                (this.getEndTime() - this.getStartTime())
            );
        }

        return (
            (this.visibleValues * (timeNumber - this.getStartTime(true))) /
            (this.getEndTime(true) - this.getStartTime(true))
        );
    }

    indexOf(entry, onlyVisible) {
        return this.getIndexOfTime(entry.startTimes[0].getMillenniumTime(), onlyVisible);
    }

    lastIndexOf(entry, onlyVisible) {
        return this.getIndexOfTime(entry.endTimes[0].getMillenniumTime(), onlyVisible);
    }

    valueAt(index, onlyVisible = true) {
        if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
            throw new Error(`Index out of bounds in TimeHeader.valueAt(${index})`);
        }

        return new MillenniumTime(
            this.getStartTime(onlyVisible) + Math.round(index * this.discreteStep)
        );
    }

    getStartTime(onlyVisible?) {
        if (onlyVisible) {
            return this.limits.startTime + this.discreteStep * this.firstVisibleValue;
        }
        return this.limits.startTime;
    }

    getEndTime(onlyVisible?) {
        if (onlyVisible) {
            return (
                this.limits.startTime +
                this.discreteStep * (this.firstVisibleValue + this.visibleValues)
            );
        }
        return this.limits.startTime + this.limits.timeCount;
    }

    getValues() {
        let time = this.getStartTime();
        const values: any[] = [];

        while (time < this.getEndTime()) {
            values.push(time);
            time = time + this.discreteStep;
        }

        return values;
    }

    hasInfo() {
        return false;
    }

    getLabel(time, size) {
        const date = new MillenniumDateTime(MillenniumDate.today(), new MillenniumTime(time));
        if (size < Header.Label.L) {
            return SimpleDateFormat.format(date, Language.getDateFormat("date_f_hh"));
        }
        return SimpleDateFormat.format(date, Language.getDateFormat("date_f_hh_mm"));
    }

    isCurrent(time, currentDateTime) {
        if (!currentDateTime) {
            return false;
        }
        const now = currentDateTime.getMillenniumTime().getTimeNumber();
        const current = new MillenniumTime(time).getTimeNumber();
        return Math.floor(now / this.discreteStep) === Math.floor(current / this.discreteStep);
    }

    setLimits(limits) {
        const header = Header.prototype.setLimits.call(this, limits);

        let firstVisibleValue = this.firstVisibleValue;
        if (this.limits) {
            if (this.getStartTime() + firstVisibleValue * this.discreteStep < limits.startTime) {
                firstVisibleValue = 0;
            } else {
                firstVisibleValue =
                    firstVisibleValue +
                    (this.getStartTime() - limits.startTime) / this.discreteStep;
            }
        }

        let visibleValues = this.visibleValues;
        if (visibleValues + firstVisibleValue > limits.timeCount / this.discreteStep) {
            firstVisibleValue = limits.timeCount / this.discreteStep - visibleValues;
        }
        if (firstVisibleValue < 0) {
            visibleValues = visibleValues - Math.abs(firstVisibleValue);
            firstVisibleValue = 0;
        }

        return header.immutableSet({
            _firstVisibleValue: firstVisibleValue,
            visibleValues,
        });
    }

    // eslint-disable-next-line no-unused-vars
    getSettings(providers) {
        const settings = Header.prototype.getSettings.call(this);
        const self = this;

        // User MillenniumDate for setting and getting firstVisibleValue
        const firstVisibleValue = settings.find("firstVisibleValue");
        firstVisibleValue.label = Language.get("cal_reservation_list_column_start_time");
        firstVisibleValue.type = "array";
        firstVisibleValue.limit = 1;
        firstVisibleValue.get = function () {
            const start = self.limits.getStartTime().getTimeNumber();
            const end = self.limits.getEndTime().getTimeNumber();
            return _.range(start, end, self.discreteStep).map((timestamp, index) => ({
                value: index,
                label: new MillenniumTime(timestamp).format("HH:mm"),
                selected: index === self.firstVisibleValue,
            }));
        };
        firstVisibleValue.set = function (fVV) {
            return self.immutableSet({ firstVisibleValue: fVV });
        };

        const visibleValues = settings.find("visibleValues");
        visibleValues.label = Language.get("cal_reservation_list_column_end_time");
        visibleValues.type = "array";
        visibleValues.limit = 1;
        visibleValues.get = function () {
            const start = self.valueAt(0).getTimeNumber() + self.discreteStep;
            const end = self.limits.getEndTime().getTimeNumber();
            return _.range(start, end + self.discreteStep, self.discreteStep).map(
                (timestamp, index) => ({
                    value: index + 1,
                    label: new MillenniumTime(timestamp).format("HH:mm"),
                    selected: index + 1 === self.visibleValues,
                })
            );
        };
        visibleValues.set = function (vVs) {
            return self.immutableSet({ visibleValues: vVs });
        };

        return settings;
    }

    toJSON() {
        const json = Header.prototype.toJSON.call(this);
        return _.extend(json, {
            firstValue: this.firstVisibleValue * this.discreteStep,
            kind: "time",
            size: 0,
            timeProvider: true,
            visibleValues: this.visibleValues * this.discreteStep,
        });
    }
}
