import {
    MillenniumDate,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import Lang from "../lib/Language";
import { Header } from "./Header";
import { Limits } from "./Limits";
import _ from "underscore";

export const DATE_HEADER_DAYS_IN_FULL_WEEK = 7;
const DAYS_IN_SIX_DAY_WEEK = 6;
const DAYS_IN_WORK_WEEK = 5;
const SATURDAY = 6;

export class DateHeader extends Header {
    daysPerWeek: number;
    opensOnToday: boolean;

    constructor(visibleValues?, firstVisibleValue?, subheader?) {
        super(
            visibleValues || DATE_HEADER_DAYS_IN_FULL_WEEK,
            firstVisibleValue,
            subheader,
            "DateHeader"
        );
        this.limits = Limits.getDefault();
        this.firstVisibleValue = 0;
        this.majorStepLimit = DATE_HEADER_DAYS_IN_FULL_WEEK;
        this.daysPerWeek = DATE_HEADER_DAYS_IN_FULL_WEEK;
        this.size = 18;
        this.opensOnToday = true;
    }

    getIndexOfDate(date, onlyVisible?: boolean): any {
        if (!this.isWeekdayVisible(date)) {
            throw new Error(
                `${date.toString()} is a weekday (${MillenniumWeekday.WEEKDAYS[
                    date.getDay()
                ].getRepresentation()}) not visible in this header.`
            );
        }

        if (this.daysPerWeek === DATE_HEADER_DAYS_IN_FULL_WEEK) {
            const start = onlyVisible ? this.valueAt(0) : this.getStartDate();
            return date.getDayNumber() - start.getDayNumber();
        }
        // When taking hidden weekends into account, we are only interested in
        // the change of work weeks, no matter the localized week format.
        const ISO_8601_FIRST_DAY_IN_WEEK = MillenniumWeekday.MONDAY;
        const ISO_8601_DAYS_IN_FIRST_WEEK = 4;

        let startDate = this.getStartDate();
        if (onlyVisible) {
            startDate = startDate.addWeeks(Math.floor(this.firstVisibleValue / this.daysPerWeek));
            const weekBeforeRemainder = startDate.getWeek(
                true,
                ISO_8601_FIRST_DAY_IN_WEEK,
                ISO_8601_DAYS_IN_FIRST_WEEK
            );
            startDate = startDate.addDays(this.firstVisibleValue % this.daysPerWeek);
            const newStartWeek = startDate.getWeek(
                true,
                ISO_8601_FIRST_DAY_IN_WEEK,
                ISO_8601_DAYS_IN_FIRST_WEEK
            );
            if (!this.isWeekdayVisible(startDate) || newStartWeek !== weekBeforeRemainder) {
                startDate = startDate.addDays(DATE_HEADER_DAYS_IN_FULL_WEEK - this.daysPerWeek);
            }
        }
        const week = startDate.getMillenniumWeek(Lang.firstDayOfWeek, Lang.daysInFirstWeek);
        const weekStartOffset = week.dayOfWeek2WeekdayNumber(startDate.getDay());
        startDate = startDate.addDays(-weekStartOffset); // First day of week

        const diffDays = date.getDayNumber() - startDate.getDayNumber();
        return (
            Math.floor(diffDays / DATE_HEADER_DAYS_IN_FULL_WEEK) * this.daysPerWeek +
            (diffDays % DATE_HEADER_DAYS_IN_FULL_WEEK) -
            weekStartOffset
        );
    }

    getStartDate() {
        const start = this.limits?.getStartDate();
        if (this.isWeekdayVisible(start)) {
            return start;
        }
        const TWO_DAYS = 2;
        return start.addDays(start.getDay() === SATURDAY ? TWO_DAYS : 1);
    }

    getConvertedIndex(index, visibleDays?: number): any {
        const start = this.getStartDate();
        const end = this.getEndDate();
        const firstVisibleDate = this.valueAt(index, false);
        const allValues = _.range(start.getDayNumber(), end.getDayNumber() + 1).map(
            (num) => new MillenniumDate(num)
        );
        const weekdayValues = allValues.filter((date) => this.isWeekdayVisible(date, visibleDays));

        if (visibleDays && visibleDays < DATE_HEADER_DAYS_IN_FULL_WEEK) {
            return weekdayValues.findIndex(
                (date) => date.getDayNumber() === firstVisibleDate.getDayNumber()
            );
        }

        return allValues.findIndex(
            (date) => date.getDayNumber() === firstVisibleDate.getDayNumber()
        );
    }

    isWeekdayVisible(date, visibleDays = this.daysPerWeek) {
        if (visibleDays === DAYS_IN_WORK_WEEK) {
            return !date.isWeekend(Lang.firstDayOfWeek);
        }
        if (visibleDays === DAYS_IN_SIX_DAY_WEEK) {
            const weekday = MillenniumWeekday.WEEKDAYS[date.getDay()];
            return weekday !== MillenniumWeekday.getLocalizedWeekdayList(Lang.firstDayOfWeek)[6];
        }
        return true; // We're viewing all week days
    }

    getEndDate() {
        return this.limits.getEndDate();
    }

    containsDate(date: any) {
        const firstDate = this.getStartDate().getDayNumber();
        const endDate = this.getEndDate().getDayNumber();

        if (date.getDayNumber() > endDate || date.getDayNumber() < firstDate) {
            return false;
        }
        return this.isWeekdayVisible(date);
    }

    valueAt(index, onlyVisibleBool?: boolean) {
        const onlyVisible = onlyVisibleBool !== undefined ? onlyVisibleBool : true;
        if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
            throw new Error(`Index out of bounds in DateHeader.valueAt(${index})`);
        }

        let position = index;
        if (onlyVisible) {
            position = index + this.firstVisibleValue;
        }

        if (this.daysPerWeek === DATE_HEADER_DAYS_IN_FULL_WEEK) {
            return this.getStartDate().addDays(position);
        }

        const startDate = this.getStartDate();
        const week = startDate.getMillenniumWeek(Lang.firstDayOfWeek, Lang.daysInFirstWeek);
        const weekStartOffset = week.dayOfWeek2WeekdayNumber(startDate.getDay());
        position = position + weekStartOffset;

        return startDate
            .addWeeks(Math.floor(position / this.daysPerWeek))
            .addDays(position % this.daysPerWeek)
            .addDays(-weekStartOffset);
    }

    lastIndexOf(entry: any, onlyVisible: any) {
        return this.getIndexOfDate(entry.endTimes[0].getMillenniumDate(), onlyVisible) + 1;
    }

    isFirstDayOfWeek(date: any) {
        return date.getDay() === Lang.firstDayOfWeek.getAbsoluteWeekdayNumber();
    }

    getLabel(date: any, size: any) {
        switch (size) {
            case Header.Label.XS:
                return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_e"));
            case Header.Label.S:
                return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_e_d"));
            case Header.Label.M:
                return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_ee_d"));
            case Header.Label.L:
                return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_eee_m_d"));
            default:
                return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_eee_yyyy_mmm_d"));
        }
    }

    setWeekendVisible(visibleDays: number) {
        if (this.daysPerWeek === visibleDays) {
            return this;
        }

        const updatedHeader = this.immutableSet({
            daysPerWeek: visibleDays,
        });
        if (visibleDays === DAYS_IN_WORK_WEEK) {
            // Remove weekends
            return updatedHeader.immutableSet({
                firstVisibleValue: this.getConvertedIndex(this.firstVisibleValue, visibleDays),
                visibleValues: removeWeekendsFromVisibleValues(this.visibleValues),
                majorStepLimit: DAYS_IN_WORK_WEEK,
            });
        }
        if (visibleDays === DAYS_IN_SIX_DAY_WEEK) {
            return updatedHeader.immutableSet({
                firstVisibleValue: this.getConvertedIndex(this.firstVisibleValue, visibleDays),
                visibleValues: removeWeekendsFromVisibleValues(this.visibleValues),
                majorStepLimit: DAYS_IN_SIX_DAY_WEEK,
            });
        }
        return updatedHeader.immutableSet({
            firstVisibleValue: this.getConvertedIndex(this.firstVisibleValue, visibleDays),
            visibleValues: addWeekendsToVisibleValues(this.visibleValues),
            majorStepLimit: DATE_HEADER_DAYS_IN_FULL_WEEK,
        });
    }

    isWeekday(date: any) {
        return !date.isWeekend(Lang.firstDayOfWeek);
    }

    indexOf(entry, onlyVisible) {
        return this.getIndexOfDate(entry.startTimes[0].getMillenniumDate(), onlyVisible);
    }

    increaseMajorStep() {
        const week = new MillenniumWeek(
            this.valueAt(0).addDays(DATE_HEADER_DAYS_IN_FULL_WEEK),
            Lang.firstDayOfWeek,
            Lang.daysInFirstWeek
        );
        let firstDateInWeek = week.getStartOfWeek().getMillenniumDate();
        if (firstDateInWeek.getDayNumber() > this.getEndDate().getDayNumber()) {
            firstDateInWeek = new MillenniumDate(
                this.getEndDate().getDayNumber() - (this.visibleValues - 1)
            );
        }

        // If the date lands on a weekday not visible, see if stepping forward a day or two helps (i.e. so that we get to Monday if we land on a hidden weekend)
        if (
            this.daysPerWeek !== DATE_HEADER_DAYS_IN_FULL_WEEK &&
            !this.isWeekdayVisible(firstDateInWeek)
        ) {
            // eslint-disable-next-line no-console
            console.log(firstDateInWeek.toString());
            firstDateInWeek = firstDateInWeek.addDays(1);
            if (!this.isWeekdayVisible(firstDateInWeek)) {
                firstDateInWeek = firstDateInWeek.addDays(1);
                if (!this.isWeekdayVisible(firstDateInWeek)) {
                    return this;
                }
            }
        }

        try {
            const newFirst = this.getIndexOfDate(firstDateInWeek);
            return this.immutableSet({
                firstVisibleValue: newFirst,
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            return this;
        }
    }

    decreaseMajorStep() {
        const firstDate = this.valueAt(0);
        let week; // If the first visible day is the first day of the week, step back a week. Otherwise, step back to the first day of that week.
        if (this.isFirstDayOfWeek(firstDate)) {
            week = new MillenniumWeek(
                firstDate.addDays(-this.daysPerWeek),
                Lang.firstDayOfWeek,
                Lang.daysInFirstWeek
            );
        } else {
            week = new MillenniumWeek(firstDate, Lang.firstDayOfWeek, Lang.daysInFirstWeek);
        }
        let firstDateInWeek = week.getStartOfWeek().getMillenniumDate();

        if (firstDateInWeek.getDayNumber() < this.getStartDate().getDayNumber()) {
            firstDateInWeek = new MillenniumDate(this.getStartDate().getDayNumber());
        }

        // If the date lands on a weekday not visible, see if stepping forward a day or two helps (i.e. so that we get to Monday if we land on a hidden weekend)
        if (
            this.daysPerWeek !== DATE_HEADER_DAYS_IN_FULL_WEEK &&
            !this.isWeekdayVisible(firstDateInWeek)
        ) {
            // eslint-disable-next-line no-console
            console.log(firstDateInWeek.toString());
            firstDateInWeek = firstDateInWeek.addDays(1);
            if (!this.isWeekdayVisible(firstDateInWeek)) {
                firstDateInWeek = firstDateInWeek.addDays(1);
                if (!this.isWeekdayVisible(firstDateInWeek)) {
                    return this;
                }
            }
        }

        try {
            const newFirst = this.getIndexOfDate(firstDateInWeek);
            return this.immutableSet({
                firstVisibleValue: newFirst,
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            return this;
        }
    }

    isCurrent(date, currentDateTime) {
        if (!currentDateTime) {
            return false;
        }
        return currentDateTime.getMillenniumDate().equals(date);
    }

    getValues() {
        const dates: any[] = [];
        let i = this.getStartDate().getDayNumber();

        while (i <= this.getEndDate().getDayNumber()) {
            const date = new MillenniumDate(i);
            if (this.isWeekdayVisible(date)) {
                dates.push(date);
            }
            i++;
        }

        return dates;
    }

    getVisibleValues() {
        const dates: any[] = [];
        let i = this.valueAt(0).getDayNumber();

        while (dates.length < this.visibleValues) {
            const date = new MillenniumDate(i);
            if (this.isWeekdayVisible(date)) {
                dates.push(date);
            }
            i++;
        }

        return dates;
    }

    getInfo(value, size, customWeekNames = []) {
        if (value.getDay() !== Lang.firstDayOfWeek.getAbsoluteWeekdayNumber()) {
            return null;
        }

        if (customWeekNames.length > 0) {
            const week = value.getMillenniumWeek(Lang.firstDayOfWeek, Lang.daysInFirstWeek);
            const customWeek = getCustomWeek(week, customWeekNames);
            if (customWeek) {
                if (size > Header.Label.L) {
                    return customWeek.getLongestName();
                }
                return customWeek.getShortestName();
            }
        }

        const weekFormat = Lang.getDateFormat("date_f_v_yyyy_ww");
        if (size > Header.Label.L && weekFormat.indexOf("d") === -1) {
            return SimpleDateFormat.format(
                value,
                `${weekFormat} (${Lang.getDateFormat("date_f_m_d")})`
            );
        }
        return SimpleDateFormat.format(value, weekFormat);
    }

    getCellsPerInfoCell() {
        return this.daysPerWeek;
    }

    isNewSection(index) {
        return this.valueAt(index).getDay() === Lang.firstDayOfWeek.getAbsoluteWeekdayNumber();
    }

    getSections() {
        const result: any[] = [];
        this.getVisibleValues().forEach((value, index) => {
            if (this.isNewSection(index)) {
                result.push(index);
            }
        });
        return result;
    }

    setLimits(limits) {
        const header = super.setLimits(limits);
        let firstVisibleValue =
            this.firstVisibleValue +
            (this.limits.getStartDate().getDayNumber() - limits.getStartDate().getDayNumber());
        if (
            limits.getStartDate().getDayNumber() + firstVisibleValue >
            limits.getEndDate().getDayNumber()
        ) {
            firstVisibleValue =
                limits.getEndDate().getDayNumber() -
                limits.getStartDate().getDayNumber() -
                this.visibleValues;
        }
        if (firstVisibleValue < 0) {
            firstVisibleValue = 0;
        }
        return header.immutableSet({ firstVisibleValue });
    }

    getSettings() {
        const settings = super.getSettings();
        const self = this;

        if (this.limits.isStartDayAbsolute) {
            settings.items.push({
                id: "opensOnToday",
                label: Lang.get("nc_date_header_opens_on_today"),
                type: "boolean",
                get: () => self.opensOnToday,
                set: (opensOnToday) => self.immutableSet({ opensOnToday }),
            });
        }

        const firstVisibleValue = settings.find("firstVisibleValue");
        firstVisibleValue.isDisabled = () => self.opensOnToday;
        firstVisibleValue.label = Lang.get("cal_reservation_list_column_start_date");
        firstVisibleValue.type = "date";
        firstVisibleValue.get = function () {
            return self.valueAt(0);
        };
        firstVisibleValue.set = function (date) {
            return self.immutableSet({ firstVisibleValue: self.getIndexOfDate(date) });
        };

        const visibleValues = settings.find("visibleValues");
        visibleValues.label = Lang.get("cal_res_side_view_visible_days");

        const daysPerWeek: any = {};
        daysPerWeek.id = "function";
        daysPerWeek.label = Lang.get("cal_header_weekdays");
        daysPerWeek.type = "array";
        daysPerWeek.limit = 1;
        const weekdays = MillenniumWeekday.getLocalizedWeekdayList(Lang.firstDayOfWeek);
        const labels = Lang.getShortWeekdayLabels();
        daysPerWeek.get = function () {
            return [
                {
                    label: Lang.get(
                        "nc_date_header_weekdays_x_to_y",
                        labels[weekdays[0].getDay()],
                        labels[weekdays[4].getDay()]
                    ),
                    value: DAYS_IN_WORK_WEEK,
                    selected: self.daysPerWeek === DAYS_IN_WORK_WEEK,
                },
                {
                    label: Lang.get(
                        "nc_date_header_weekdays_x_to_y",
                        labels[weekdays[0].getDay()],
                        labels[weekdays[5].getDay()]
                    ),
                    value: DAYS_IN_SIX_DAY_WEEK,
                    selected: self.daysPerWeek === DAYS_IN_SIX_DAY_WEEK,
                },
                {
                    label: Lang.get(
                        "nc_date_header_weekdays_x_to_y",
                        labels[weekdays[0].getDay()],
                        labels[weekdays[6].getDay()]
                    ),
                    value: DATE_HEADER_DAYS_IN_FULL_WEEK,
                    selected: self.daysPerWeek === DATE_HEADER_DAYS_IN_FULL_WEEK,
                },
            ];
        };
        daysPerWeek.set = function (value) {
            return self.setWeekendVisible(value);
        };
        settings.items.push(daysPerWeek);

        return settings;
    }

    toJSON() {
        const json = super.toJSON();
        let firstVisibleValue = this.firstVisibleValue;
        if (this.daysPerWeek < DATE_HEADER_DAYS_IN_FULL_WEEK) {
            firstVisibleValue = this.getConvertedIndex(firstVisibleValue, this.daysPerWeek);
        }
        return _.extend(json, {
            dayProvider: true,
            firstValue: firstVisibleValue,
            daysPerWeek: this.daysPerWeek,
            kind: "date",
            size: this.size,
            opensOnToday: this.opensOnToday,
        });
    }

    get firstVisibleValue() {
        return this._firstVisibleValue;
    }

    set firstVisibleValue(value) {
        let val = value;
        if (!_.isNumber(val) || val % 1 !== 0) {
            throw new Error(Lang.get("nc_validation_error_not_an_integer", val));
        }

        if (val > this.length() - this.visibleValues) {
            val = this.length() - this.visibleValues;
        }
        if (val < 0) {
            val = 0;
        }

        this._firstVisibleValue = val;
    }
}

// Make firstVisibleValue getter/setter enumerable.
Object.defineProperty(Header.prototype, "firstVisibleValue", { enumerable: true });

const addWeekendsToVisibleValues = function (visibleValues) {
    if (visibleValues === DATE_HEADER_DAYS_IN_FULL_WEEK) {
        return (
            Math.floor(visibleValues / DAYS_IN_WORK_WEEK) * DATE_HEADER_DAYS_IN_FULL_WEEK +
            (visibleValues % DAYS_IN_WORK_WEEK)
        );
    }
    if (visibleValues === DAYS_IN_SIX_DAY_WEEK) {
        return (
            Math.floor(visibleValues / DAYS_IN_WORK_WEEK) * DAYS_IN_SIX_DAY_WEEK +
            (visibleValues % DAYS_IN_WORK_WEEK)
        );
    }
    return visibleValues;
};

const removeWeekendsFromVisibleValues = function (visibleValues) {
    if (visibleValues === DAYS_IN_WORK_WEEK) {
        return (
            Math.floor(visibleValues / DATE_HEADER_DAYS_IN_FULL_WEEK) * DAYS_IN_WORK_WEEK +
            (visibleValues % DATE_HEADER_DAYS_IN_FULL_WEEK)
        );
    }
    if (visibleValues === DAYS_IN_SIX_DAY_WEEK) {
        return (
            Math.floor(visibleValues / DATE_HEADER_DAYS_IN_FULL_WEEK) * DAYS_IN_SIX_DAY_WEEK +
            (visibleValues % DATE_HEADER_DAYS_IN_FULL_WEEK)
        );
    }
    return visibleValues;
};

const getCustomWeek = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name;
    }
    return null;
};
