import React from "react";
import MOCSettings from "./MOCSettings";
import Language from "../../lib/Language";
import PropTypes from "prop-types";
import { Table } from "@timeedit/tecore-table";
import * as API from "../../lib/TimeEditAPI";
import { REQUEST_TYPES } from "../preferences/PrefsCoreAPI";
import { Macros } from "../../models/Macros";
import ContextMenu from "../../lib/ContextMenu";
import _ from "underscore";
import Log from "../../lib/Log";

const TimeEditAPI: any = API;

let _selectAll: any = null;
let _clearSelection: any = null;

type TCreateObjectsRequestPayload = [
    {
        connectTo: {
            extId: string;
            typeExtId: string;
            object: {
                extid: string;
                fields: any[];
                id: number; //The Id of the primary object
                name: string;
            };
            type: {
                class: string;
                extid: string;
                id: number; // The Id for the type of the primary object
                name: string;
            };
        };
        numberOfObjects: number;
        type: {
            class: string;
            extid: string;
            id: number; //The Id for the type of the created object
            name: string;
        };
        typeExtId: string;
    }
];

interface IMemberObjectCreatorProps {
    groupRequests: TCreateObjectsRequestPayload;
    objects: any;
    callback: undefined | ((didCreate: boolean) => void);
    setClearSelection: undefined | any;
    style: any;
    width: number;
    isActive: boolean;
    classes: any;
    topLeft: any;
}

export default class MemberObjectCreator extends React.Component<
    IMemberObjectCreatorProps,
    { objects: any; types: any; selectedIds: any[]; selectedIncompleteObjects: any[] }
> {
    constructor(props) {
        super(props);
        this.state = { objects: [], types: [], selectedIds: [], selectedIncompleteObjects: [] };
    }

    static defaultProps = {
        groupRequests: [],
        callback: undefined,
    };

    static contextTypes = {
        presentModal: PropTypes.func,
        fireEvent: PropTypes.func,
    };

    componentDidMount() {
        //mixpanel.track("Object creator opened", {});
        TimeEditAPI.findTypes((allTypes) => {
            this.setState({ types: allTypes });
            if (this.props.groupRequests && this.props.groupRequests.length > 0) {
                this.showModal();
            }
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.groupRequests !== this.props.groupRequests) {
            this.setState({ objects: [], selectedIds: [] }, () => {
                if (this.props.groupRequests.length > 0) {
                    /*mixpanel.track("Object creator got submission data", {
                        numberOfRequests: this.props.groupRequests.length,
                    });*/
                    this.showModal();
                }
            });
        }
    };

    getColumns = () => [
        {
            name: "Object",
            primary: true,
            sortable: false,
            id: 1,
            defaultWidth: 200,
        },
        {
            name: "Type",
            primary: true,
            sortable: false,
            id: 2,
            defaultWidth: 200,
        },
        {
            name: "Connected to",
            primary: true,
            sortable: false,
            id: 3,
            defaultWidth: 200,
        },
        {
            name: "Status",
            primary: true,
            sortable: false,
            id: 4,
            defaultWidth: 200,
        },
    ];

    findTypeName = (typeId) => this.state.types.find((type) => type.id === typeId).name;

    getTableRow = ({ index }) => {
        const object = this.state.objects[index];
        const columns = this.getColumns();
        const row = {};
        columns.forEach((column) => {
            row[column.name] = "";
        });
        if (object) {
            columns.forEach((column) => {
                if (column.name === "Object") {
                    row[column.name] = object.name ? object.name : object.extid;
                }
                if (column.name === "Type" && object.types) {
                    row[column.name] = object.types.map(this.findTypeName).join(", ");
                }
                if (column.name === "Connected to") {
                    // Lists all members and relations, which will not be useful if we ever list existing objects like this
                    let connectedTo: String[] = [];
                    if (object.members) {
                        connectedTo = [...object.members.map((o) => o.name || o.extid || o.id)];
                    }
                    if (object.related) {
                        connectedTo = [
                            ...connectedTo,
                            ...object.related.map((o) => o[0].name || o[0].extid || o[0].id),
                        ];
                    }
                    row[column.name] = connectedTo.join(", ");
                }
                if (column.name === "Status" && object.status) {
                    row[column.name] = object.status;
                }
            });
        }
        return row;
    };

    selectAll() {
        if (_selectAll) {
            _selectAll();
        }
    }

    registerSelectAll(fn) {
        _selectAll = fn;
    }

    _setClearSelection(clearFunction) {
        _clearSelection = clearFunction;

        if (this.props && this.props.setClearSelection) {
            this.props.setClearSelection(_clearSelection);
        }
    }

    onDelete() {
        TimeEditAPI.deleteObjects(this.state.selectedIds, true, (results) => {
            let deletedObjects: any[] = [];
            results.forEach((result, index) => {
                if (!result.result) {
                    deletedObjects.push(this.state.selectedIds[index]);
                }
            });
            /*mixpanel.track("Object creator deleted objects", {
                numberOfObjects: this.state.selectedIds.length
            });*/
            this.setState({
                objects: this.state.objects.filter(
                    (object) => deletedObjects.indexOf(object.id) === -1 || object.id === 0
                ),
            });
        });
    }

    onEdit() {
        this.context.fireEvent("prefsComponent", Macros.Event.REQUEST_OPERATION, {
            operationType: REQUEST_TYPES.EDIT_OBJECT,
            data: {
                objectIds: this.state.selectedIds,
                types: [],
                incompleteObjects: this.state.selectedIncompleteObjects,
            },
            callback: (creationResult) => {
                console.log(creationResult);
                if (!creationResult || creationResult.length === 0) {
                    // User cancelled, nothing to do
                    return;
                }
                TimeEditAPI.exportObjectsBatch(creationResult, (result) => {
                    console.log(result, this.state.objects);
                    let updatedObjects = [...this.state.objects];
                    result.forEach((newObject) => {
                        newObject.name = newObject.fields[0].values.join(",") || newObject.extid;
                        newObject.status = "Saved";
                        let index = this.state.objects.map((o) => o.extid).indexOf(newObject.extid);
                        updatedObjects.splice(index, 1, newObject);
                    });
                    this.setState({ objects: updatedObjects });
                });
            },
        });
    }

    onSelect(objectIndex, selectedIndexes, event) {
        let selectedIds = selectedIndexes.map((index) => this.state.objects[index]?.id);
        let selectedIncompleteObjects: any = [];
        selectedIndexes.forEach((index) => {
            if (this.state.objects[index].id === 0) {
                selectedIncompleteObjects.push(this.state.objects[index]);
            }
        });
        this.setState({ selectedIds, selectedIncompleteObjects });
    }

    showModal() {
        const content = (
            <div style={{ overflow: "scroll", height: "300px" }}>
                <MOCSettings
                    groupRequests={this.props.groupRequests}
                    callback={(newObjects = []) => {
                        // Will get invoked multiple times, how best add? Just plain add all the way?
                        this.setState({ objects: [...this.state.objects, ...newObjects] });
                        if (this.props.callback) {
                            this.props.callback(true);
                        }
                    }}
                />
            </div>
        );
        const displayKey = null;
        const title = Language.get("nc_create_members_and_related_objects");
        const buttons = [{ title: "Close", remember: false }];
        this.context.presentModal(content, displayKey, title, buttons);
    }

    render() {
        let style = { width: this.props.width, height: "100%" };
        if (this.props.style) {
            style = this.props.style;
        }

        const classes = _.extend(
            {
                reservationList: true,
                active: this.props.isActive,
                inactive: !this.props.isActive,
            },
            this.props.classes
        );

        return (
            // @ts-ignore:next-line
            <div className={_.classSet(classes)} style={style}>
                <div className="listTopLine">
                    {this.props.topLeft}
                    <span>
                        <button
                            title={Language.get("cal_reservation_action_button_select_all")}
                            ref="selectAllButton"
                            onClick={this.selectAll}
                            className="selectAll"
                        />
                        <button
                            title={Language.get("menu_edit")}
                            ref="editSelectedButton"
                            onClick={this.onEdit.bind(this)}
                            disabled={this.state.selectedIds.length === 0} // disabled={this.state.selectedIds.length !== 1}
                            className="massChange"
                        />
                        <button
                            title={Language.get("dialog_remove")}
                            ref="deleteSelectedButton"
                            onClick={this.onDelete.bind(this)}
                            className="massRemoveObject"
                        />
                        <button
                            disabled={this.state?.objects.length > 0}
                            className="uiText teButton"
                            onClick={this.showModal.bind(this)}
                        >
                            Create New Objects
                        </button>
                        {this.props.groupRequests && this.props.groupRequests.length > 0
                            ? `${this.props.groupRequests.length} requests`
                            : null}
                    </span>
                </div>
                <div className="listSecondLine">
                    <div className="summary">
                        <span className="listTitle summaryLabel">Created Objects</span>
                    </div>
                </div>
                <Table
                    ref="table"
                    log={Log}
                    highlight={Table.HIGHLIGHT.MULTI}
                    language={Language}
                    rowCount={this.state?.objects.length}
                    registerSelectAll={this.registerSelectAll.bind(this)}
                    setClearSelection={this._setClearSelection.bind(this)}
                    onSelect={this.onSelect.bind(this)}
                    isRowLoaded={() => true}
                    loadMoreRows={_.noop}
                    columns={this.getColumns()}
                    allColumns={this.getColumns()}
                    isSortable={false}
                    presentModal={this.context.presentModal}
                    contextMenu={ContextMenu}
                    getTableRow={this.getTableRow}
                />
            </div>
        );
    }
}
